<template>
    <div class="contanier">
        <Header></Header>

        <div class="loginForm">
            <div class="form_header">
                <img class="logoImg2" src="@/assets/image/small_logo.svg" alt="" width="63" height="75" >
                <div class="line"></div>
                <div class="text">{{ $t('login.title') }}</div>
            </div>
            <div>
                <a-form
                    :model="form"
                    :style="{marginTop: '10px' }"
                >
                    <a-form-item
                        field="name"
                        :hide-label="true"
                        :help="emailMsg"
                        :validate-status="email_status"
                        feedback
                    >
                        <a-input
                            v-model="form.email"
                            allow-clear
                            :placeholder="$t('login.email2')"
                        >
                            <template #prefix>
                                <icon-email :size="28"/>
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item
                        field="password"
                        :hide-label="true"
                        :help="pdwMsg"
                        :validate-status="password_status"
                        feedback
                    >
                        <a-input-password
                            v-model="form.password"
                            allow-clear
                            :placeholder="$t('login.password')"
                        >
                            <template #prefix>
                                <icon-lock :size="28"/>
                            </template>
                        </a-input-password>
                    </a-form-item>

                    <Verify
                        class="verify"
                        @success="success" 
                        mode="pop" 
                        captchaType="blockPuzzle"
                        :imgSize="{ width: '408px', height: '155px' }"
                        ref="verifyRef"
                    ></Verify>

                    <div class="form_btn">
                        <a-button type="primary" class="loginInBtn" :disabled="loading" @click="beforeLogin">
                            <span v-if="!loading">{{ $t('login.login') }}</span>  
                            <a-spin v-else>{{ $t('login.logining') }}</a-spin>
                        </a-button>

                        <div type="text" class="forgetPsd" @click="forgetPsd">
                            {{ $t('login.forgetPsd') }}
                        </div>
                    </div>
                    
                    <!-- <div class="line"></div> -->

                    <!-- <div class="becomeDealer" @click="becomeDealer">{{ $t('login.becomeDealer') }} <icon-right /></div> -->
                    
                
                    <!-- <div class="psdTool">
                        <a-checkbox v-model="remember" class="remember" @change="rememberPassword">{{ $t('login.remmenber') }}</a-checkbox>
                    </div> -->
                </a-form>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, reactive, ref, toRefs, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { LoginApi } from '@/api/LoginApi.js';
    import { Message, Alert } from '@arco-design/web-vue';
    import jwt_decode from "jwt-decode";
    import { useI18n } from 'vue-i18n';
    import Header from './component/Header.vue';
    import Verify from "@/components/verifition/Verify";

    const router = useRouter()
    const { t } = useI18n()
    const loading = ref(false)
    const language = localStorage.getItem('dealerLocale')? ref(localStorage.getItem('dealerLocale')) : ref('en')
    const emailMsg = ref('')
    const pdwMsg = ref('')
    const email_status = ref('')
    const password_status = ref('')
    const form = reactive({
        captcha_verification:'',
        email:'',
        password:''
    })
    const verifyRef = ref(null)
    // const remember = ref(false)

    

    const vaild = ()=>{
        if(!form.email && !form.password){
            emailMsg.value = t('login.emailEmpty')
            email_status.value = 'error'
            pdwMsg.value = t('login.passwordEmpty')
            password_status.value = 'error'
            return false
        }else if(!form.email){
            emailMsg.value = t('login.emailEmpty')
            email_status.value = 'error'
            return false
        }else if(!form.password){
            pdwMsg.value = t('login.passwordEmpty')
            password_status.value = 'error'
            return false
        }

        return true
    }

    const beforeLogin = ()=>{
        if(!vaild()) return

        if(form.captcha_verification == ''){
            verifyRef.value.show()
            return
        }
    }

    // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
    const success = (params)=>{ 
        console.log(params,'params')
        form.captcha_verification = params.captchaVerification
        login()
    }

    const login = async ()=>{
        loading.value = true
        let res = await LoginApi.getInstance().login(form)
        if(res.code == 0){
            let dealerInfo = jwt_decode(res.access_token)
            sessionStorage.setItem('token',res.access_token)
            sessionStorage.setItem('dealerInfo',JSON.stringify(dealerInfo))
            Message.success(t('login.loginSuccessful'))
            router.replace('/OrderList')
            
        }else{
            Message.error(res.msg)
            form.captcha_verification = ''
            loading.value = false
        }
    }

    const forgetPsd = ()=>{
        router.push('/ForgetPwd')
    }

    const becomeDealer = ()=>{
        router.push('/BecomeDealer')
    }

    // const rememberPassword = ()=>{
    //     if(remember.value){
    //         localStorage.setItem('dealerLoginInfo',JSON.stringify(form))
    //     }else{
    //         localStorage.removeItem('dealerLoginInfo')
    //     }
    // }

    // const getRole = async (userInfo)=>{
    //     let res = await LoginApi.getInstance().getRole(userInfo.id)
    //     if(res.code == 0){
    //         loading.value = false
    //         userInfo.role = res.data.join(',')
    //         sessionStorage.setItem('dealerInfo',JSON.stringify(userInfo))
    //         router.replace('/OrderList')
    //         // getMenuBtnByRole()
    //     }else{
    //         Message.error(res.msg)
    //         loading.value = false
    //     }
    // }

    // const getMenuBtnByRole = async()=>{
    //     if(sessionStorage.getItem('userInfo')){
    //         let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    //         let res = await RoleApi.getInstance().getMenuBtnByRole(userInfo.role)
    //         if(res.code == 0){
    //             let btnData = [], menuId = []
    //             res.data.forEach(i=>{
    //                 i.menu.forEach(k=>{
    //                     if(k.menu_category == 2){
    //                         btnData.push(k)
    //                     }
    //                 })
    //                 i.menuId.forEach(k=>{
    //                     if(!menuId.includes(k)){
    //                         menuId.push(k)
    //                     }
    //                 })
    //             })

    //             // console.log(btnData,'btnData',menuId)
    //             let permissionBtns = []
    //             btnData.forEach(i=>{
    //                 if(menuId.includes(i.id)){
    //                     permissionBtns.push(i.menu_name)
    //                 }
    //             })
    //             let arr = []
    //             permissionBtns.forEach(i=>{
    //                 if(!arr.includes(i)){
    //                     arr.push(i)
    //                 }
    //             })
    //             sessionStorage.setItem('permissionBtns',JSON.stringify(arr))
    //             if(userInfo.role == '6' || userInfo.role == '7'){ // 如果用户是ZU，则仅能查看出库管理页面
    //                 router.replace('/StockOutList')
    //             }else{
    //                 router.replace('/OrderList')
    //             }
                
    //         }else{
    //             Message.error(res.msg)
    //         }
    //     }
    // }

    watch(()=>form.email, (val)=>{
        if(val){
            emailMsg.value = ''
            email_status.value = ''
        }
    })

    watch(()=>form.password, (val)=>{
        if(val){
            pdwMsg.value = ''
            password_status.value = ''
        }
    })

    onMounted(()=>{
        if(localStorage.getItem('dealerLoginInfo')){
            form.email = JSON.parse(localStorage.getItem('dealerLoginInfo')).email
            form.password = JSON.parse(localStorage.getItem('dealerLoginInfo')).password
            // remember.value = true
        }
    })


</script>

<style lang="less" scoped>
.contanier{
    width: 100%;
    height: 100vh;
    background:url('@/assets/image/login_bg.jpg') no-repeat;
    background-size: cover;

    .header{
        display: flex;
        padding: 15px 30px;
        width: 100%;
        height: 70px;
        background:#fff;
        box-sizing: border-box;
        align-items: center;
        
        .hd_left{
            display: flex;
            flex: 7;
            justify-content: flex-start;
            align-items: center;

            .hd_text{
                display: inline-block;
                margin-left: 75px;
                font-size: 20px;
            }
        }

        .hd_right{
            display: flex;
            flex: 1;
            justify-content: flex-end;
            // align-items: flex-end;
        }
    }

    .loginForm{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -224px;
        margin-top: -35vh;
        padding: 40px 20px;
        width: 448px;
        height: auto;
        box-sizing: border-box;
        background: #fff;
        border-radius: 5px; 
        text-align: center;

        .form_header{
            width: 370px;
            margin: 0 auto;

            .text{
                font-size: 24px;
                font-weight: 700;
                color: #000;
            }
        }

        .line{
            margin: 20px auto;
            height: 1px;
            width: 200px;
            background:  #eee;
        }

        /deep/ .arco-form-item{
            margin-bottom: 0px;
        }

        /deep/ .arco-form-item-content-wrapper{
            margin-top: 15px;
        }

        /deep/ .arco-input-wrapper .arco-input{
            height: 35px;
        }

        /deep/ button:focus, 
        /deep/ button:focus-visible{
            outline: none;
        }

        .form_btn{
            margin-top: 20px;
            height: 50px;
            line-height: 50px;

            .loginInBtn{
                float: left;
                height: 50px;
                width: 110px;
                border-radius: 50px;
                background: #000;
            }

            .forgetPsd{
                float: right;
                vertical-align: middle;
                color: #000;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .becomeDealer{
            text-align: left;
            font-size: 14px;
            cursor: pointer;
        }
    }
}

.verify{
    margin: 10px 0;
}
</style>