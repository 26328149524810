/*
 * @Author: caizexin caizexin@kjgb.net
 * @Date: 2022-11-22 17:02:45
 */

import axios from 'axios';
// import NProgress from 'NProgress'
// import 'nprogress/nprogress.css'

// NProgress.inc(0.4)
// NProgress.configure({ easing: 'ease', speed: 600, showSpinner: false})

const BASE_API_URL = import.meta.env.VITE_BASE_URL
console.log(import.meta.env,'axios')

class Data {
    static getInstance() {
        if (!this.instance) this.instance = new Data()
        return this.instance
    }

    getData(url, params, method, dataType) {
        url = import.meta.env.MODE == 'development' ? url : `${BASE_API_URL}${url}`

        if (import.meta.env.MODE == 'production') {
            if (url.indexOf('/api') > -1){url = url.replace('/api', '')} 
        }

        if (method == 'GET' || method == 'get') {
            let urlParam = ''
            for (let key in params) {
                urlParam = urlParam == '' ? `?${key}=${encodeURIComponent(params[key])}` : `${urlParam}&${key}=${encodeURIComponent(params[key])}`
            }
            url = url + urlParam
        }
        // console.log(url,'url')

        // 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJzdWIiOiJ6c0BoZXBoYS5jb20iLCJyb2xlIjpbMV0sInVzZXJfbmFtZSI6InpoYW5nc2FuIiwiaWQiOjEzLCJtZW51IjpbMjgsMjksMzAsMzEsMzIsMzMsMzQsMzUsMzcsMzgsMzksNDAsNDEsNDIsNTEsNTIsNTMsNTQsNjQsNjUsNjYsNjcsNjksNzAsNzMsNzQsNzYsODMsODQsODUsOTEsOTMsOTQsMTAwLDEwMSwxMzYsNTksNjAsNjgsNzEsNzUsNzcsOTgsOTksMTAyLDEwMyw5MiwxMjQsMTI1LDEyNiwxMjcsMTI4LDEyOSw0NCw4Miw2MSw2Miw2Myw3OSw4MCw4MSwxMTEsMTEyLDExNCwxMTUsODcsODgsMTE2LDExNywxMTgsMTE5LDEyMCwxMjEsMTIyLDEzMCwxMzEsMTMyLDEzMywxMzQsMTM1LDg5LDQ3LDQ5LDUwLDU2LDU3XSwiZXhwIjoxNzMwNzczMDE4LCJpYXQiOjE3MTQ4NzU0MTgsImVtYWlsIjoienNAaGVwaGEuY29tIiwianRpIjoiYzQ5NGVhYzctNmJhZi00NGViLWE1ZjgtMDMxNWJiOTc5M2UzIn0.vBIIfG9NBxMiR4HaAPQGPm1idmpfX7Pav7SWXg9atTmajuy9sUpvg5fZ-imhl9-Ip5k59PJQsYH9j_rI32QoXA' 
        let token = sessionStorage.getItem('token')
        let config = {
            headers: {
                'Content-Type': dataType? dataType : 'application/json;charset=utf-8',
                'Authorization': token == null? '' : 'Bearer ' + token,
            },
            method: method,
            timeout: 6 * 60 * 1000,
            url: url,
            data: params,
            responseType:'blob'
        }

        // NProgress.start();
        return new Promise((resolve, reject) => {
            axios(config).then(res => {
                if (res.data.code == 401) {

                } else {
                    // resolve(res.data)

                    if(res.data.type === 'application/json'){
                        let reader = new FileReader()
                        reader.readAsText(res.data,'utf-8')
                        reader.onload = ()=>{
                            let msg = JSON.parse(reader.result)
                            resolve(msg)
                        }
                    }else if(res.data.type === 'application/octet-stream'){
                        resolve(res.data)
                    }else{
                        resolve(res.data)
                    }
                }
            }).catch(error => {
                // console.log(error, 'error')
                if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1) {
                    resolve(error)
                }

                // resolve(error.response.data)

                else if (error.response && error.response.data.type === 'application/json') {
                    let reader = new FileReader()
                    reader.readAsText(error.response.data, 'utf-8')
                    reader.onload = () => {
                        let msg = JSON.parse(reader.result)
                        resolve(msg)
                    }
                }
            })
        })
    }
}

export {
    Data
}