/*
 * @Author: caizexin caizexin@kjgb.net
 * @Date: 2023-07-10 11:32:28
 */
export default{
    base:{
        headerText:'Online Order System',
        noEmpty:'cannot be empty!',
        noPage:'Page does not exist',
        sorry:'Sorry',

        login:'Login',
        feishuLogin:'Login Using Feishu',
        larkLogin:'Login Using Lark',
        tip:'Tips',
        operateSuccess:'Operation successful!',
        cancel:'Cancel',
        ok:'OK',
        operate:'Operate',
        more:'More',
        save:'Save',
        loading:'Loading...',
        yes:'YES',
        no:'NO',
        copyFailed:'Copy Failed',
        formatError:'format error',
        download:'Download',
        delete:'Delete',
        select:'Select',

        enterSomething:'Please enter something...',
        
        clearCache:'Clear Cache',
        logout:'Logout',
        search:'Search',
        add:'Add',
        edit:'Edit',
        reset:'Reset',

        saveSuccess:'Successfully Saved',
        addSuccess:'Successfully Added',
        createSuccess:'Created successfully',
        updateSuccess:'Successfully Update',
        addFail:'Adding Failed：',
        editSuccess:'Successfully Edited',
        editFail:'Editing Failed：',
        delSuccess:'Successfully Deleted',
        delFail:'Delete Dailed：',
        syncSuccess:'Synchronization Successful',
        syncFail:'Synchronization Failed：',
        downloadSuccess:'Download Successful',
        notSync:'Not synchronized',
        delConfirmTip:'Are you sure to delete?',
        noEmpty:'cannot be empty!',
        sendSuccess:'Successfully Send',
        sending:'Sending...',
        assignSuccess:'Successfully Assign',
        selectData:'Please select data',
        cancelSuccess:'Successfully Cancel',
        success:'Success',
        fail:'Fail',
    },
    login:{
        title:"Welcome to Hepha Online Order System",
        login:'Log in',
        logout:'Logout',
        logining:'Logging in...',
        password:'Password',
        forgetPsd:'Forgot password?',
        becomeDealer:'Become a dealer',
        forgetTitle:'Reset password',
        forgetText:'Please fill in the email address you used at registration to reset your password.',
        forgetText2:'We have sent a verification code to your email. If you have not received it, please wait patiently and make sure you have provided the correct email address.',
        sent:'Sent',
        email:'E-mail address',
        email2:"E-mail",
        emailEmpty:'E-mail cannot be empty!',
        passwordEmpty:'Password cannot be empty!',
        emailMsgEmpty:'E-mail address is not empty!',
        code:'Verification code',
        codeMsgEmpty:'Verification code is not empty!',
        newPasswordEmpty:'New password is not empty!',
        confirmPasswordEmpty:'Confirm password is not empty!',
        editPwd:'Password modification successful',
        vaildPwd:'Password inconsistency!',
        reset:'Reset',
        newPassword:'New Password',
        confirmPassword:'Confirm Password',
        sentCodeTip:'You can only request a verification code once per minute.',
        sentCodeTip2:'It will take ',
        sentCodeTip3:" to resend the verification code",

        error_email:'【Company Address】E-mail format error!',
        store_error_email:'【Store Address】E-mail format error!',
        delivery_error_email:'【Delivery Address】E-mail format error!',
        passwordVaildMsg:'Password length 6-10 digits, consisting of numbers and letters',
        logoutTip:'Are you sure you want to log out?',
        logoutTitle:'Logout tip',

        loginSuccessful:'Login successful!',
        resetSuccessful:'Reset password successfully!',

        completeVeriftyTitle:'Please complete security verification',
        swipeRight:'Swipe right to complete verification',
        verificationFailed:"Verification failed",
        verificationSuccessful:'Verification successful',
        clickMoreOne:'Please click on them one by one',
    },
    menu:{
        orderList:'Order List', 
    },
    becomeDealer:{
        title:'BECOME A DEALER',
        text:'We are very pleased to welcome you as a HEPHA dealer! Please give us some information about your business so that we can set you up correctly!',
        companyAddress:'Company address',
        storeAddress:'Store address',
        deliveryAddress:'Delivery address',
        companyName:'Company name',
        contactPerson:'Contact person',
        land:'Land',
        country:'Country',
        street:'Street',
        houseNumber:'House number',
        plz:'Zip code',
        ort:'City',
        telephone:'Telephone',
        mobile:'Mobile',
        email:'E-mail',
        website:'Website',
        city:'City',
        zipCode:'Zip code',
        vatNo:'VAT No.',
        sameCompanyAddress:'Same as company address',
        sameStoreAddress:'Same as store address',
        severalAddresses:'Several addresses, therefore later entry.',
        branches:'Branches?',
        branches2:'branches',
        no:'No',
        yes:'Yes',
        yesHave:'Yes, we have',
        salesAreaApprox:'Sales area approx',
        serviceWorkshop:'Service workshop?',
        foundation:'Year of foundation',
        top5Brands:'Your top 5 brands',
        checkText:'I hereby confirm the data protection regulations of Hepha GmbH and expressly authorize HEPHA GmbH to contact me by e-mail or telephone.',
        sendNow:'SEND NOW',
        shopName:'Shop name',
        bico:'BICO',

        storeStreetEmpty:'The street of the store address cannot be empty!',
        storeHouseNumberEmpty:'The house number of the store address cannot be empty!',
        storeOrtEmpty:'The city of the store address cannot be empty!',
        storePlzEmpty:'The zip code of the store address cannot be empty!',
        storePhoneEmpty:'The telephone of the store address cannot be empty!',
        storeEmailEmpty:'The email of the store address cannot be empty!',

        deliveryStreetEmpty:'The street of the delivery address cannot be empty!',
        deliveryHouseNumberEmpty:'The house number of the delivery address cannot be empty!',
        deliveryOrtEmpty:'The city of the delivery address cannot be empty!',
        deliveryPlzEmpty:'The zip code of the delivery address cannot be empty!',
        deliveryPhoneEmpty:'The telephone of the delivery address cannot be empty!',
        deliveryEmailEmpty:'The email of the delivery address cannot be empty!',
        deliveryBranchEmpty:'The branches of the delivery address cannot be empty!',
        deliveryWorkshopEmpty:'The service workshop of the delivery address cannot be empty!',
        submitSuccessful:'Submit successfully!',
        waitAuditTip:'Thanks for your registration, we will go back to you soon.'
    },
    order:{
        customer:'Customer',
        level:'Level',

        Trekking7:'Trekking 7 MY24',
        Mountain7:'Mountain 7 MY24',
        Mountain7Carbon:'Mountain 7 Carbon MY24',
        Trekking7MY23:'Trekking 7 MY23',

        projectNumber:'SKU',
        modelYear:'Model Year',
        modelVersion:'Model & Version',
        color:'Color',
        frame:'Frame',
        size:'Size',
        uvp:'UVP(€)',
        ek:'EK(€)',
        availability:'Availability',
        orderQty:'Order Quantity',
        deliveryType:'Delivery Type',

        immediately:'Immediately',
        unavailable:'Unavailable',
        currentlyNotAvailable:'Currently not available',

        currentlyNotAvailable2:'Currently Not Available',
        currentlyNotAvailableTip:'Currently, we do not have any inventory available. However, we will be able to deliver in the future.',

        numberOfBikes:'Number of bikes',
        total:'Total',
        orderNow:'ORDER NOW',
        allPrices:'All prices net plus shipping',
        
        summaryOrderTitle:'Summary of your order',
        total:'Total',
        addNote:'Add a note to your order',
        subTotal:'Subtotal',
        sendOrder:'SEND ORDER',
        cancel:'CANCEL',
        accept:'By sending an order you accept our',
        and:'and',
        term:'terms & conditions',
        policy:'privacy policy.',
        orderSentSuccessful:'Order sent successfully',
        orderSentSuccessful2:'Your order has been submitted!',
        orderSentSuccessful3:'You will receive a confirmation by e-mail.',

        standard:'Standard',
        express:'Express',
        collection:'Self-pickup',

        black:'Black',
        grey:'Grey',
        lightGrey:'LightGrey',
        darkGrey:'DarkGrey',
        blueGrey:'BlueGrey',
        warmGrey:'WarmGrey',
        moos:'Moos',
        sand:'Sand',
        mint:'Mint',
        olive:'Olive',
        anthracite:'Anthracite',

        highStep:'High step',
        lowStep:'Low step',
        stepThrough:'Step Through',
        hardtail:'Hardtail',
        fullSuspension:'Full suspension',

        deliveryTypeEmptyTip:'Delivery Type cannnot be empty!',
        resetFilter:'Delete all filters',
        exceedAvailableStock:'Exceed Available Stock',

        selectDealer:'Select Dealer',
        dealerInactiveTip:'Please contact our sales team +49 (0) 8142 2844481 or sales@hepha.com',
    }
    
}