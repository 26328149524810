<template>
    <div class="header">
            <div class="hd_left">
                <img class="logoImg" src="@/assets/image/logo.svg" alt="" width="150" >
                <div class="hd_text">{{ $t('base.headerText') }}</div>
            </div>
            <div class="hd_center">
                <slot name="customerName"></slot>
            </div>
            <div class="hd_right">
                <a-select 
                    v-model="language"
                    :style="{width:'70px',float:'right',marginTop:'10px'}" 
                    placeholder="Select"
                    @change="changeSelect"
                >
                    <a-option value="en">En</a-option>
                    <a-option value="de">De</a-option>
                    <!-- <a-option value="zh">Zh</a-option> -->
                </a-select>
                <!-- <a-button 
                    v-if="dealerInfo.source"
                    type="text" 
                    class="logout"
                    style="margin-top: 9px;outline: none"
                    @click="logout"
                >{{ $t('login.logout') }}</a-button> -->

                <a-dropdown class="userName" v-if="dealerInfo.source">
                    <a-button style="margin-top:10px;background:#fff">{{ dealerInfo.email }} <icon-down/></a-button>
                    <template #content>
                        <a-doption @click="logout">
                            <icon-poweroff :size="16"/>
                            {{ $t('login.logout') }}
                        </a-doption>
                    </template>
                </a-dropdown>
            </div>
            
    </div>
</template>

<script setup>
    import { onMounted, reactive, ref, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { LoginApi } from '@/api/LoginApi.js';
    import { Message, Modal } from '@arco-design/web-vue';
    import { useI18n } from 'vue-i18n';
    // import { useLocale } from '@/store/index.js'

    const router = useRouter()
    const { t } = useI18n()
    // const localeStore = useLocale()

    const language = localStorage.getItem('dealerLocale')? ref(localStorage.getItem('dealerLocale')) : ref('en')
    const dealerInfo = ref({}) 

    const changeSelect = (val)=>{
        localStorage.setItem('dealerLocale',val)
        location.reload()
    }

    const logout = ()=>{
        Modal.warning({
            title: t('login.logoutTitle'),
            titleAlign:'start',
            hideCancel:true,
            content: t('login.logoutTip'),
            okText:t('base.ok'),
            onOk:()=>{
                sessionStorage.removeItem('token')
                sessionStorage.removeItem('dealerInfo')
                router.replace('/Login')
            }
        })
    }

    // watch(localeStore,(val)=>{
    //     dealerInfo.value.source = val.dealerSource
    // })

    onMounted(()=>{
        if(sessionStorage.getItem('dealerInfo')){
            dealerInfo.value = JSON.parse(sessionStorage.getItem('dealerInfo'))
        }
        
    })
</script>

<style lang="less" scoped>
 .header{
        display: flex;
        padding: 15px 30px;
        width: 100%;
        height: 70px;
        background:#fff;
        box-sizing: border-box;
        align-items: center;
        
        .hd_left{
            display: flex;
            flex: 7;
            justify-content: flex-start;
            align-items: center;

            .hd_text{
                display: inline-block;
                margin-left: 75px;
                font-size: 20px;
            }
        }

        .hd_center{
            display: flex;
            flex:2;
            // padding-left: 60px;
        }

        .hd_right{
            display: flex;
            flex: 1;
            justify-content: flex-end;
            margin-top: -10px;
            // align-items: flex-end;
        }

        /deep/ .arco-btn-text:hover, 
        /deep/ .arco-btn-text[type='button']:hover, 
        /deep/ .arco-btn-text[type='submit']:hover{
            background-color: transparent;
        }

        /deep/ button:focus, 
        /deep/ button:focus-visible{
            outline: none;
        }
    }
</style>