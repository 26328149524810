/*
 * @Author: caizexin caizexin@kjgb.net
 * @Date: 2023-07-28 09:16:52
 * @LastEditors: caizexin caizexin@kjgb.net
 * @LastEditTime: 2024-03-19 18:32:10
 * @FilePath: \vue-hepha-order\src\utils\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import CryptoJS from 'crypto-js';

// 日期转成时间戳（中国时区）
const dateToTimestamp = (date)=>{
    return new Date(date).getTime().toString().slice(0,10)
}

// 根据不同时区，将时间戳转成当地所在时区的日期
const dateToTimestamp_timeDiff = (date, timezoneOffset , timeDiff)=>{
    let timestamp = timezoneOffset == -8? new Date(date).getTime() - timeDiff : new Date(date).getTime()
    console.log(timestamp,'timestamp')
    return timestamp.toString().slice(0,10)
}

// 时间戳转成日期
const timestampToDate = (timestamp)=>{
    let date = new Date(timestamp)
    let YY = date.getFullYear()
    let MM = date.getMonth() + 1 < 10? `0${date.getMonth() + 1}` :date.getMonth() + 1
    let DD = date.getDate() < 10? `0${date.getDate()}` : date.getDate()
    let hh = date.getHours() < 10? `0${date.getHours()}` : date.getHours()
    let mm = date.getMinutes() < 10? `0${date.getMinutes()}` : date.getMinutes()
    let ss = date.getSeconds() < 10? `0${date.getSeconds()}` : date.getSeconds()

    return `${YY}-${MM}-${DD} ${hh}:${mm}:${ss}`
}

const watchSessionStroage = (watchKey)=>{
    var orignalSetItem = sessionStorage.setItem;
    sessionStorage.setItem = function(key, newValue) {
        var setItemEvent = new Event("setItemEvent");
        setItemEvent.newValue = newValue;
        window.dispatchEvent(setItemEvent);
        orignalSetItem.apply(this, arguments);
    }

    // 获取sessionStorage中需要监听的值loginInfor
    var info = sessionStorage.getItem(watchKey);
    if (info) { // 符合条件
        console.log(JSON.parse(info),'info');
    } else {
        // 否则继续监听
        window.addEventListener("setItemEvent", function(e) {
            if ((e.key = watchKey)) {
                var _this = sessionStorage.getItem(watchKey);
                if (_this != e.newValue) {
                    if (e.newValue) {
                        console.log(e.newValue,'new');
                        console.log(_this,'_this');
                    }
                }
            }
        })
    }
}

// 获取当前日期
const getNowDate = ()=>{
    let now = new Date()
    let YY = now.getFullYear()
    let MM = now.getMonth() + 1 < 10? `0${now.getMonth() + 1}` :now.getMonth() + 1
    let DD = now.getDate() < 10? `0${now.getDate()}` : now.getDate()

    return `${YY}-${MM}-${DD}`
}

// 获取前7天日期（当前日期往前推7天）
const getPrev7Date = ()=>{
    let date = getNowDate()
    let nowTimestamp = new Date(date).getTime()
    let prevSevenTimestamp = nowTimestamp - 7 * 24 * 60 * 60 * 1000 
    let prevSevenDate = timestampToDate(prevSevenTimestamp)
    console.log(nowTimestamp,'timestamp',prevSevenTimestamp, prevSevenDate.split(' ')[0])

    return prevSevenDate.split(' ')[0]
}

// 获取前n天日期（当前日期往前推1天）
const getPrevNDate = (n)=>{
    let date = getNowDate()
    let nowTimestamp = new Date(date).getTime()
    let prevSevenTimestamp = nowTimestamp - n * 24 * 60 * 60 * 1000 
    let prevSevenDate = timestampToDate(prevSevenTimestamp)
    console.log(nowTimestamp,'timestamp',prevSevenTimestamp, prevSevenDate.split(' ')[0])

    return prevSevenDate.split(' ')[0]
}

// 获取当前日期是第几周
const getWeek = (dateTime)=> {
    var time, week, checkDate = new Date(dateTime);
    checkDate.setDate(checkDate.getDate() + 4 - (checkDate.getDay() || 7));
    time = checkDate.getTime();
    checkDate.setMonth(0);
    checkDate.setDate(1);
    week = Math.floor(Math.round((time - checkDate) / 86400000) / 7) + 1;
    console.log(week,'week')
    return week;
}

// 格式化金额，小数点转换：逗号 => 点
const formatterToPoint = (number)=> {
    // return String(number.toFixed(2)).replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') // 3是千分位，4是万分位
    return String(number).replace(',','.')
}

// 格式化金额，小数点转换：点 => 逗号
const formatterToComma = (number, isTranfer)=> {
    console.log(number,'number2222', isTranfer)
    return isTranfer? number : String(number).replace('.',',')
}

const key = CryptoJS.enc.Utf8.parse('1234123412ABCDEF') // 十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('ABCDEF1234123411') // 十六位十六进制数作为密钥偏移量

//解密
const decryption = (word)=> {
    let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    let decryptedstr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedstr.toString();
}

//加密
const encryption = (word)=> {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7} );
    return encrypted.ciphertext.toString().toUpperCase();
}

// 获取url参数
const getParam = (url, id)=> {
    url = url+ "";
    let regstr = "/(\\?|\\&)" + id + "=([^\\&]+)/";
    let reg = eval(regstr);
    //eval可以将 regstr字符串转换为 正则表达式
    let result = url.match(reg);
    if (result && result[2]) {
        return result[2];
    }
}

export {
    dateToTimestamp,
    dateToTimestamp_timeDiff,
    timestampToDate,
    watchSessionStroage,
    getNowDate,
    getPrev7Date,
    getPrevNDate,
    formatterToPoint,
    formatterToComma,
    getWeek,

    decryption,
    encryption,

    getParam
}