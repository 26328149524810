<template>
    <div class="container">
        <Header class="header">
            <template v-slot:customerName>
                <div class="dealer_info">
                    <div>
                        <span class="label">{{ $t('order.customer') }}</span>
                        <span class="value">{{ dealer.customer_name }}</span>
                        <!-- <span class="value" :class="{'isSale':isSale}" @click="selectDealer">{{ dealer.customer_name }}</span> -->
                    </div>
                    <div>
                        <span class="label">{{ $t('order.level') }}</span>
                        <span class="value">{{ dealer.hf_customer_level }}</span>
                    </div>
                </div>
            </template>
        </Header>

        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, reactive, ref, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { LoginApi } from '@/api/LoginApi.js';
    import { OrderApi } from '@/api/OrderApi.js';
    import { Message } from '@arco-design/web-vue';
    import { useI18n } from 'vue-i18n';
    import Header from './login/component/Header.vue';
    // import { useLocale } from '@/store/index.js'


    const router = useRouter()
    const { t } = useI18n()
    // const localeStore = useLocale()

    const language = localStorage.getItem('dealerLocale')? ref(localStorage.getItem('dealerLocale')) : ref('en')
    const dealer = ref('')
    const dealerInfo = ref({}) // 当前登录的经销商信息
    // const dealerOption = ref([])
    // const isSale = ref(false)

    // 获取经销商
    const getDealer = async ()=>{
        let res = await OrderApi.getInstance().getDealer({id:dealerInfo.value.source})
        if(res.code == 0){
            dealer.value = res.data[0]
        }else{
            Message.error(res.msg)
        }
    }

    // 获取经销商下拉数据
    // const getDealerOption = async ()=>{
    //     let res = await OrderApi.getInstance().getSaleDealerList({})
    //     if(res.code == 0){
    //         dealerOption.value = []
    //         res.data.length > 0 && res.data.forEach(i=>{
    //             if(i.user_id == dealerInfo.value.id){
    //                 dealerOption.value.push({
    //                     label:i.customer_name,
    //                     value:i.source_id,
    //                 })
    //             }
    //         })
    //         localeStore.changeSaleDealerOption(dealerOption.value)
    //     }else{
    //         Message.error(res.msg)
    //     }
    // }

    // const selectDealer = ()=>{
    //     localeStore.changeShowSelectDealer(true)
    // }

    // watch(localeStore,(val)=>{
    //     console.log(val.dealerSource,'dealerSource')
    //     dealerInfo.value.source = val.dealerSource
    //     localeStore.changeDealerSourceId(val.dealerSource)
    //     getDealer()
    // })

    onMounted(()=>{
        if(sessionStorage.getItem('dealerInfo')){
            dealerInfo.value = JSON.parse(sessionStorage.getItem('dealerInfo'))
        }

        getDealer()

        // 登录人是销售人员
        // if(dealerInfo.value.role.join(',').indexOf('13') > -1){
        //     isSale.value = true
        //     await getDealerOption()
        //     localeStore.changeDealerSource(dealerOption.value[0].value)
        // }else{
        //     getDealer()
        // }
    })

</script>

<style lang="less" scoped>
.container{
    width:100%;
    min-height: 100vh;
    background: #F5F7F8;

    .header{
        position: fixed;
        top: 0;
        left: 0;
        width:100%;
        z-index: 1000;

        .dealer_info{
            padding: 7px 40px;
            // margin-bottom: 10px;
            // margin-left: 40px;
            background: #fff;
            border-radius: 10px;
            font-size: 13px;
            color: #3C4257;

            .label{
                display: inline-block;
                width: 80px;
            }

            .value{
                font-weight: 700;
            }
        }

        .isSale{
            cursor: pointer;
        }
    }

    .content{
        margin:10px 40px 20px ;
    }
}

// /deep/ .arco-select-view-single.arco-select-view-size-medium .arco-select-view-value{
//     min-height: 20px;
// }
// /deep/ .arco-select-view-single.arco-select-view-size-medium .arco-select-view-input, 
// /deep/ .arco-select-view-single.arco-select-view-size-medium .arco-select-view-value{
//     padding-top: 0; 
//     padding-bottom: 0;
// }
</style>