export default{
    base:{
        username:'用户名',
        password:'密码',
        email:'邮箱',
        newPassword:'新密码',
        confirmPassword:'确认密码',
        signIn:'登录',
        signUp:'注册',
        confirm:'确认',
        newPassword:'新密码',
        confirmPassword:'确认密码',
        login:'登录',
        feishuLogin:'飞书登录',
        larkLogin:'Lark登录',
        tip:'提示',
        operateSuccess:'操作成功！',
        cancel:'取消',
        ok:'确认',
        operate:'操作',
        more:'更多',
        save:'保存',
        sync:'同步',
        loading:'加载中...',
        exporting:'正在导出...',
        exportSuccess:'导出成功',
        yes:'是的',
        no:'不是',
        line:'行',
        sorry:'抱歉',
        noPage:'当前页面不存在...',
        shareLink:'复制链接',
        submitting:'提交中...',
        previewFile:'预览文件',
        qrcode:'二维码',
        copyFailed:'复制失败',
        formatError:'格式错误',
        download:'下载',
        delete:'删除',

        clearCache:'清除缓存',
        logout:'退出登录',
        search:'查询',
        reset:'重置',
        add:'新增',
        edit:'编辑',
        detail:'详情',
        input:'请输入',
        select:'请选择',
        selecteDate:'请选择日期',

        saveSuccess:'保存成功',
        addSuccess:'新增成功',
        createSuccess:'创建成功',
        updateSuccess:'更新成功',
        addFail:'新增失败：',
        editSuccess:'编辑成功',
        editFail:'编辑失败：',
        delSuccess:'删除成功',
        delFail:'删除失败：',
        syncSuccess:'同步成功',
        syncFail:'同步失败：',
        notSync:'未同步',
        delConfirmTip:'是否确认删除?',
        noEmpty:'不能为空!',
        sendSuccess:'发送成功',
        sending:'发送中...',
        send:'发送',
        assignSuccess:'分配成功',
        selectData:'请选择数据',
        cancelSuccess:'取消成功',
        success:'成功',
        fail:'失败',
        amountIncludeTax:'金额含税',
        discountIncludeTax:'折扣含税',
        uploadImg:'上传图片',

        filter:'筛选',
        notLess0:'不能小于0',
        large:'大',
        medium:'中',
        small:'小',

        open:'展开',
        close:'收起',

        enterEmail:'输入邮箱',
        enterUser:'输入用户',
        enterSomething:'请输入',
        emailReceiveTip:'发送dealer收账清单的邮箱',
        operation:'操作',
        date:'发票日期',
        deleteConfirmTip:'是否确认删除？',
    },
    login:{
        title:'你好！让我们开始吧',
        subTitle:'登录以继续',
        bottomTitle:'没有账户？',
        create:'创建',
        remmenber:'记住账号',
        fogget:'忘记密码？',
        usernameEmpty:'用户名不能为空！',
        emailEmpty:'邮箱不能为空！',
        passwordEmpty:'密码不能为空！',
        feishuLoginError:'登录异常！错误信息',
        backFeishuLogin:'点击按钮，将重新跳转至飞书登录授权页面',
    },
    signUp:{
        title:'新建账号？',
        subTitle:'注册很容易。只需几步',
        bottomTitle:'已经有帐户？',
        tip:'注册成功！前往登录',
        error_email:'邮箱格式不正确！',
        emailEmpty:'邮箱不能为空！',
        passwordVaildMsg:'密码需由6-10位数字和字母组成',
        password:'密码 (需由6-10位数字和字母组成)',
        emailVaildMsg:'该邮箱已被注册！'
    },
    confirmPwd:{
        title:'忘记密码？',
        subTitle:'重置密码很容易。只需几步',
        bottomTitle:'已经有帐户？',
        editPwd:'密码修改成功',
        vaildPwd:'密码不一致！',
        emailMsgEmpty:'邮箱不能为空',
        newPasswordEmpty:'新密码不能为空！',
        confirmPasswordEmpty:'确认密码不能为空！',
    },
    menu:{
        home:'首页', 
        orderList:'订单管理', 
        returnOrder:'退货杂单管理',
        invoiceList:'发票管理',
        productList:'产品管理', 
        customerList:'客户管理', 
        dealerList:'经销商管理',
        saleGoal:'销售目标',
        financial:'财务管理',
        financialReceipts:'财务收账',
        errorLog:'报错日志',
        config:'基础配置',
        soSyncConf:'SO同步过滤配置',
        orderSource:'同步订单来源',
        // emailReceive:'经销商逾期邮件配置',
        emailReceive:'邮箱配置',
        countryCodeConfig:'国家代码配置',
        setting:'系统设置', 
        role:'角色权限管理', 
        user:'用户角色管理',
        orderSubList:'订单列表',
        returnExchangeList:'退换货列表',
        addEmail:'邮箱注册管理',
        dashboard:'数据看板',
        dataStatistics:'数据统计',
        stockOutList:'出库管理',
        menuManage:'菜单管理',
        dealerGoal:'经销商目标',
        saleFrameGoal:'销售车架目标',
        cash:'现金流',
        accounts:'应付',
        priceList:'同步ERP价目表',
    },
    order:{
        deliver:'发货申请',
        invoice:'填写发货申请单',
        curStock:'当前库存数量',
        deliverRecord:'发货记录',
        viewLogistics:'物流信息',
        refundRecord:'退款记录',
        downloadInvoice:'下载发票',
        addProduct:'新增订单行',
        syncToERP:'同步订单到ERP',
        delete:'删除',
        cancelSO:'SO取消',
        editOrder:'编辑',
        returnFactory:'返修',

        deliverRecord2:'发货申请',
        refundRecord2:'退款',
        cancelSO2:'取消',
        cancelSORecord2:'SO取消',
        downloadInvoice2:'下载',
        sendInvoice2:'发送',
        viewLogistics2:'物流',
        base:'基础',
        invoice:'发票',
        record:'记录',
        changeOrderStatus:'状态',
        cancelOrder:'取消',
        refundOrder:'退款',
        deleteInvoice:'删除',

        finalPayment:'尾款单',
        partialPay:'部分付款',
        pay:'支付',
        refund:'退款',
        returnsAndRefund:'退货退款',
        partialRefund:'部分退款',
        partialReturnsAndRefund:'部分退货退款',
        canceled:'已取消',
        // initial:'初始',
        unship:'未发货',
        plan:'计划发货',
        partialShip:'部分发货',
        shiped:'已发货',
        signedFor:'已签收',
        close:'已关闭',
        obligation:'待付款',
        fail:'失败',
        unknown:'未知',
        partialReturn:'部分退货',
        partialExchange:'部分换货',
        return:'退货',
        exchange:'换货',
        user:'用户',
        dealer:'经销商',
        initial:'初始',
        outbound:'已出库',
        unOutbound:'未出库',
        notSalesOrder:'非销售订单',

        other:'其他',
        shopify:'Shopify',
        jobrad:'Jobrad',
        bikeExchange:'Bike Exchange',
        amazonSeller:'Amazon Seller',
        decathlon:'Decathlon',
        factoryDirectSales:'Factory Direct Sales',
        amazonVendor:'Amazon Vendor',
        alltricks:'All Tricks',

        deliverTip:'请先选择该订单下需要发货的产品',
        selectWms:'请先选择仓库，查看库存，再发货',
        courierTip:'物流公司、物流单号、发货仓库不能为空！',
        courierTip2:'物流公司、物流链接、发货仓库不能为空！',
        courierTip3:'物流公司、发货仓库不能为空！',
        quantityTip:'预计发货数量不能大于下单数量!',
        quantityTip2:'预计发货数量不能为空!',
        quantityTip3:'预计发货数量与已发货数量之和不能大于下单数量!',
        quantityTip4:'预计发货数量不能大于下单数量!',
         
        orderNumber:'订单编号',
        orderName:'订单编号', //'订单名称',
        orderSource:'订单来源',
        orderStatus:'订单状态',
        customerType:'客户类型',
        country:'国家',
        erpNo:'ERP SO No.',

        courierStatus:'物流状态',
        courierType:'物流公司',
        courierNo:'物流单号',
        orderDate:'订单日期',
        deliverQty:'发货数量',
        curQuantity:'预计发货数量',

        deliverDetailTitle:'本次发货产品明细',

        orderAmount:'订单金额',
        orderDiscount:'订单折扣',
        orderTax:'订单扣税',
        InvoiceAmount:'发票金额',
        payAmount:'支付金额',
        refundAmount:'退款金额',
        payCurrency:'支付货币',
        deliverStatus:'发货状态',
        customerType:'客户类型',
        orderTimestamp:'订单时间',
        refundTimestamp:'退款时间',
        customerEmail:'客户邮箱',
        customerName:'客户名称',
        customerInvoiceName:'客户名称(发票)',
        country:'国家',
        outboundStatus:'出库状态',
        unitCode:'单位',
        erpLineNo:'ERP行号',
        deliveryNumber:'Mes出库单号',

        orderLineNo:'订单行号',
        inventoryCode:'物料编码',
        warehouse:'发货仓库',
        quantity:'下单数量',
        needShipquantity:'需发货数量',
        shipQuantity:'发货中数量',
        shippedQuantity:'已发货数量',
        backQuantity:'退货数量',
        deliveryDate:'发货日期',
        sku:'SKU',
        productName:'产品名称',
        productName2:'名称',
        wms:'发货仓库',
        inventory:'库存',
        amount:'金额',
        discount:'折扣',
        price:'单价',
        depositAmount:'订金金额',
        torque:'扭矩',
        operate:'操作',
        frameShape:'车形',

        logisticsDetail:'物流明细',

        courierLink:'物流链接',
        referenceInventory:'参考库存数',
        happenedTime:'物流更新日期',
        
        noCourierInfo:'暂无物流信息',

        create:'创建订单',
        preAmount:'定金金额',
        handleTimestamp:'预计发货时间',
        taxRate:'税率',
        createSuccess:'创建订单成功！',
        customerErpCode:'ERP客户编码',
        baseInfo:'基本信息',
        amountInfo:'金额信息',
        customerInfo:'客户信息',
        edit:'编辑',
        cancel:'取消',
        edit2:'编辑',
        cancel2:'取消',
        delete2:'删除',
        cancelLineSO:'取消SO',
        changeConfirmTip:'是否确认将订单状态改成【支付】？',
        changeConfirmTip2:'是否确认将订单状态改成【取消】？',
        changeConfirmTip3:'是否确认将订单状态改成【退款】？',
        changeConfirmTip4:'是否确认将订单状态改成【待付款】？',
        changeConfirmTip5:'是否确认将订单状态改成【部分支付】？',
        cancelERPSOTip:'请先取消ERP SO',
        syncConfirmTip:'是否确认同步？',
        changeSKUTip:'是否确认修改SKU？',
        viewVersion:'查看版本',
        viewVersion2:'查看版本',
        operator:'操作人',
        sendInvoice:'发送发票',
        sendInvoiceMsg:'是否确认发送发票给客户，客户可下载发票进行预览？',
        sendInvoiceMsg2:'已发送邮件',
        sendInvoiceMsg3:'次，',
        sendInvoiceMsg4:'请确认是否需要再次发送',

        cancelConfirmTip:'是否确认取消？',
        cancelStatus:'取消状态',
        canceled:'已取消',
        notCancel:'未取消',
        cancelSORecord:'SO取消记录',
        type:'类型',
        syncStatus:'同步状态',
        schedulingQuantity:'计划出库数量',
        versionTime:'修改时间',

        backNumber:'退货数量',
        backStatus:'退货状态',
        returning:'退货中',
        swaping:'换货中',
        returnSuccessful:'退货成功',
        exchangeSuccessful:'换货成功',
        returned:'退货成功',
        swaped:'换货成功',
        cancelReturn:'取消退货',

        sex:'性别',
        male:'男',
        female:'女',
        unknown:'未知',

        frameNumber:'车架号',
        bikeCount:'整车数量',
        shipDate:'发货日期',
        extraDiscount:'额外折扣',
        payTypeDiscount:'支付折扣',
        cargoFreight:'运费',
        cargoFreight2:'运费',
        paymentType:'支付方式',
        paymentTypeDiscount:'支付折扣比例',
        preTaxUnitPrice:'不含税单价',
        preTaxDiscount:'不含税折扣(单台车)',
        preTaxPayAmount:'不含税金额',
        preTaxExtraDiscount:'不含税额外折扣(单台车)',
        afterTaxUnitPrice:'含税单价',
        postTaxDiscount:'含税折扣',
        postTaxPayAmount:'含税金额',
        afterTaxExtraDiscount:'含税额外折扣(单台车)',
        afterTaxDiscount:'含税折扣(单台车)',
        afterTaxPayAmount:'含税支付金额',
        summary:'金额汇总(含税)',
        preTax:'不含税',
        afterTax:'含税',
        preAfterTax:'含税/不含税',
        orderExtraDiscount:'额外折扣',
        payWithin14daysDiscount:'14天内支付的折扣金额',
        unitPrice:'单价',

        orderUse:'订单用途',
        backFlag:'车辆是否归还',
        isReturn:'归还',
        notReturn:'不归还',
        rollOverInventory:'转仓仓库',
        exportOrder:'导出订单',
        exportEBike:'导出订单车辆',
        exportCustomer:'导出订单客户信息',
        exportOrderData:'订单数据',
        exportEBikeData:'订单车辆数据',
        exportCustomerData:'订单客户数据',

        currentAmount:'当前支付金额',
        refundReasonType:'退款原因类型',
        refundReason:'退款原因',
        payAmount2:'支付金额(含税)',
        refundAmountVaildMsg:'退款金额不能大于支付金额',

        greaterThan0:'需要大于0',
        invoiceAddress:'发票地址',
        sameSKUExists:'存在相同的SKU',
        instruction:'备注',
        reissue:'补寄',
        replenishing:'补寄中',
        reissueTimestamp:'补寄日期',
        reissueInfo:'补寄信息',
        reissueNumber:'补寄单号',
        reissueSuccessful:'补寄成功',
        reissueType:'补寄类型',
        reissueQuantity:'补寄数量',
        swapInventoryCode:'物料编码',
        bom:'Bom物料编码',
        selectProduct:'请添加补寄产品',
        reasonType:'补寄原因类型',
        reason:'补寄原因',
        reissueTimestamp:'补寄发出时间',
        stockQuantity:'库存数量',

        productQuantity:'生产数量',
        productQuantityErrorTip:'生产数量不能大于下单数量',
        createUser:'创建人',

        confirmProduct:'请确认产品信息',
        frameColor:'车架颜色',
        cancelSOConfirmTip:'是否确认取消该行',
        createDate:'创建日期',

        orderType:'订单类型',
        sale:'销售',
        unSale:'非销售',
        notForSale:'非销售',

        customerNumber:'渠道来源编码',
        area:'区域',
        hfCustomerLevel:'客户等级',
        paymentType:'支付方式',
        paymentDay:'账期',

        invoiceNo:'发票号',
        userNote:'用户备注',

        deliveryType:'发货方式',
        deliverConfirmTip1:'该订单未付款，请确认是否已发货？',
        deliverConfirmTip2:'该订单部分付款，请确认是否已发货？',
        riskValidate:"风险验证",
        riskLevel:'风险等级',
        confirmedAsRisk:"确认风险",
        relieveRisks:'解除风险',
        orderRisk:'订单风险',
        riskLevel:'风险等级',
        lowRisk:'低风险',
        mediumRisk:'中风险',
        highRisk:'高风险',
        isValid:'已验证',
        isRelieve:'已解除',
        noValid:'未验证',
        confirmeSuccessful:'确认成功!',
        relieveSuccessful:'解除成功!',
        cancelSOHeadTip:'仅整单cancel才可以重新编辑订单数据，部分 cancel不能编辑订单。',

        logistics:'物流',
        downloadInvoiceMsg:'订单只有在已发货且有车架号的情况下才能开具发票',

        editOrderConfirmTip:'该订单已经同步到ERP，是否确认编辑？',
        editOrderConfirmTip1:'如果确认，请在编辑订单后，到ERP系统进行同步修改。',
        editOrderConfirmTip2:'否则，会影响数据正确性。',
        editOrderConfirmTip3:'请到ERP系统进行同步修改，',

        ECommerceVendor:'E-commerce Vendor',
        preInvoiceNo:'预开发票号',
        redownloadInvoice:'重新下载发票',
        downloadCustomDateInvoice:'生成订单发票',
        downloadInvoiceTip:'如果订单内容已更新，请点击“生成订单发票”重新生成发票内容。',
        invoiceDateTip:'可自定义发票日期',
        customerInfo:'客户信息',
        invoiceInfo:'发票信息',
        reSend:'重新发送',

        orderChannelType:'订单渠道类型',
        invoiceTimestamp:'发票日期',
        shipWeek:'预发货周别',
        shipYear:'预发货年份',

        orderConfirmation:'订单确认书',
        downloadConfirmation2:'下载订单确认书',
        downloadConfirmation:'下载',
        sendConfirmation:'发送',
        reDownloadConfirmation:'重新生成订单确认书',
        sendConfirmationMsg:'您确定要将订单确认书发送给客户吗？客户可以下载订单确认书进行预览',
    
        brandName:'品牌名称',
        partName:'零件名称',
        model:'车型',
        modelYear:'上市年份',

        day14Tip:'为鼓励回款，公司对于支付方式为“by invoice”的客户，将给予14天内付款的客户1%的折扣。',
        cancelDate:'订单取消日期',

        orderLineMaxQtyTip:'每个订单最多只能新增50行',
        deliverOrderLineMaxQtyTip:'一次最多只能选择15行',

        priceTip1:'无法获取该车辆价格，请通过以下方式解决：',
        priceTip2:'【方式1】在OMS系统同步ERP价目表',
        priceTip3:'【方式2】请联系相关人先在ERP维护价目表，然后在OMS系统同步ERP价目表',
        syncNewPrice:'同步最新单价',
    },
    stock:{
        transactionDate:'预计发货日期',
        deliveryDate:'实际发货日期',
        signTime:'签收日期',
        quantity:'数量',
        orderQty:'订单数量',
        shipQty:'实际发货数量',
        plan:'预计',
        real:'实际',
        signFor:'签收',
        signForDate:'签收日期',
        outboundOrderBikeQuantity:'下出库单整车数量',
        unoutboundBikeQuantity:'未出库整车数量',
        outboundBikeQuantity:'已出库整车数量',
        contentTip:'编辑内容不能为空',
        contentTip2:'寄出物流单号或寄出物流链接不能为空！',
        contentTip3:'退回物流单号或退回物流链接不能为空！',
        logistics:'物流信息',
        returnLogistics:'退回物流信息',
        logisticsType:'物流公司',
        logisticsNo:'物流单号',
        logisticsLink:'物流链接',
        deliveryNumber:'Mes出库单号',
        remark:'备注',
        cargoFreight:'运费',
        frameNumber:'车架号',
        confirmFrameNumber:'确认是否修改车架号?',
        po:'外箱号',
        shipment:'发货',
        return:'退货',
        exchange:'换货',
        reissue:'补寄',
        returnFactory:'返修',
        backTimestamp:'退货',
        backRealTimestamp:'收货',
        backDate:'预计退回日期',
        backReceiveDate:'实际退回日期',
        swapTimestampPlan:'预计',
        swapTimestampReal:'实际',
        swapDatePlan:'计划发货日期',
        swapDateReal:'实际发货日期',
        returnStatus:'退货状态',
        swapStatus:'换货状态',
        backQty:'退货数量',
        swapNumber:'换货单号',
        swapQty:'换货数量',
        swapLogistics:'换货物流',
        reissueNumber:'补寄单号',
        reissueStatus:'补寄状态',
        reissueQty:'补寄数量',
        cancelOutbopundConfirmTip:'该操作将会取消出库单下所有产品的出库',
        cancelSwapConfirmTip:'是否确认取消换货单',
        cancelReturnConfirmTip:'是否确认取消退货单',
        cancelReissueConfirmTip:'是否确认取消补寄单',
        cancelOutbound:'取消出库单',
        cancelOutbound2:'取消出库',
        cancelSwap:'取消换货单',
        cancelSwap2:'取消换货',
        cancelReturn:'取消退货单',
        cancelReturn2:'取消退货',
        cancelReissue:'取消补寄单',
        cancelReissue2:'取消补寄',
        shipmentDetail:'发货详情',
        returnDetail:'退货详情',
        exchangeDetail:'换货详情',
        reissueDetail:'补寄详情',
        image:'图片',
        exportShipment:'导出',
        exportShipmentData:'出库单数据',
        exportConfirmTip:'是否确认导出?',

        returningFactory:'返修中',
        returnFactorySuccessful:'返修完成',
        returnFactoryNumber:'返修单号',
        returnFactoryStatus:'返修状态',
        returnFactoryQty:'返修数量',
        returnFactoryDetail:'返修详情',

        bikeCount:'未出库整车数量',
        bikeCount1:'退货中整车数量',
        bikeCount2:'换货中整车数量',
        bikeCount3:'补寄中整车数量',
        bikeCount4:'返修中整车数量',
        bomCount:'未出库Bom数量',
        bomCount1:'退货中Bom数量',
        bomCount2:'换货中Bom数量',
        bomCount3:'补寄中Bom数量',
        asOf:'预计发货时间截止到',
        asOf2:'截止到',

        categoryType:'物料类别',

        planShipStartDate:'预计寄出开始日期',
        planShipEndDate:'预计寄出结束日期',
        realShipStartDate:'实际寄出开始日期',
        realShipEndDate:'实际寄出结束日期',

        planReturnStartDate:'预计退回开始日期',
        planReturnEndDate:'预计退回结束日期',
        realReturnStartDate:'实际退回开始日期',
        realReturnEndDate:'实际退回结束日期',

        swapCourierType:'寄出物流公司',
        swapCourierNo:'寄出物流单号',
        swapCourierLink:'寄出物流链接',
        swapCourierStatus:'寄出物流状态',
        returnCourierType:'退回物流公司',
        returnCourierNo:'退回物流单号',
        returnCourierLink:'退回物流链接',
        returnCourierStatus:'退回物流状态',

        returnLogisticsInfo:'退回物流信息',
        swapLogisticsInfo:'寄出物流信息',
        outScanned:'已扫描',

        deliveryNote:'发货单',
        deliveryNote2:'发货单',

        swapSku:'换货SKU',
    },
    returnOrder:{
        createReturnOrder:'创建退货杂单',
        selectProduct:'选择产品',
        selectProductMsg:'请选择产品',
        returnDate:'退货时间',
        returnStartDate:'退货开始日期',
        returnEndDate:'退货结束日期',
        courierType:'物流类型',
        returning:'退货中',
        returnSuccesful:'退货成功',
        backNumber:'退货单号',
    },
    invoice:{
        create2CInvoice:'创建To C发票',
        create2BInvoice:'创建To B发票',
        download:'下载',
        reDownload:'重新下载',
        reGenerate:'重新生成',
        send:'发送',
        reSend:'重新发送',
        sending:'发送中...',
        delete:'删除',
        invoiceNo:'发票号',
        orderName:'订单号',
        invoiceChildType:'出库单号',
        orderChannelType:'订单渠道类型',
        invoicType:'发票类型',
        orderType:'订单类型',
        invoiceAmount:'发票金额',
        createDate:'发票日期',
        updateDate:'更新日期',
        deliveryNumber:'出库单号',
        outboundStatus:'出库状态',
        frameNumber:'车架号',
        unOutbound:'未出库',
        outbound:'已出库',
        cancelOutbound:'取消出库',
        outScanned:'已扫描',
        quantity:'数量',
        orderDate:'订单日期',
        deliveryDate:'出库日期',
        doubleInvoiceMsg:'这个订单已经有发票了，请不要重复开票。',
        deleteMsg:'是否删除发票？（删除后，如果要重新生成发票，请单击“创建”）。',
        sendInvoiceMsg:'是否确认发送发票给客户，客户可下载发票进行预览？',
        reGenerateInvoice:'重新生成发票',
        orderQty:'订单数量',
        shipQty:'发货数量',
        downloadInvoiceTip:'如果订单内容已更新，请点击“确定”重新生成发票内容。',
        invoiceVaildStatus:'发票状态',
        vaild:'有效发票',
        invaild:'预开发票/失效发票',
        payStatus:'付款状态',
        payAmount:'付款金额',
        payAmountTip:'如果与发票金额差值小于0.5欧，则视为全额支付',
    },
    returnExchange:{
        returnAndExchange:'退货 & 换货',
        returnAndExchange2:'退货 & 换货 & 补寄',
        returnExchange:'退货/换货',
        returnExchangeRecord:'退货记录',
        returnExchangeRecord2:'退货/换货',
        exchangeRecord:'换货记录',
        reissueRecord:'补寄记录',
        type:'类型',
        status:'状态',
        erpNo:'ERP编号',
        erpLineNo:'ERP行号',
        source:'来源',
        customerErpCode:'ERP客户编码',
        quantity:'数量',
        discount:'折扣',
        sku:'SKU',
        productName:'产品名称',
        inventoryCode:'ERP物料编码',
        backTimestamp:'退货日期',
        backRealTimestamp:'退货实际收到日期',
        backReasonType:'退换货原因类型',
        backReason:'退换货原因',
        ibsVerificationType:'IBS验证方案类型',
        ibsVerificationPlan:'IBS验证方案描述',
        customerName:'客户名称',
        backNumber:'退货单号',
        swapNumber:'换货单号',
        swapInventoryCode2:'整车换货物料编码',
        swapInventory:'换货仓库',
        swapQuantity:'换货数量',
        backQuantity:'退货数量',
        returnedQuantity:'已退货数量',
        swapCourierNo:'物流单号',
        swapCourierType:'物流公司',
        swapTimestampPlan:'换货计划发出时间',
        swapTimestampReal:'换货实际发出时间',
        swapReasonType:'换货原因类型',
        swapReason:'换货原因',
        courierNo:'物流单号',
        courierType:'物流公司',
        return:'退货',
        exchange:'换货',
        backCourierNo:'退货物流单号',
        backCourierType:'退货物流公司',
        backCourierStatus:'退货物流状态',
        exchangeCourierNo:'换货物流单号',
        exchangeCourierType:'换货物流公司',
        exchangeCourierStatus:'换货物流状态',

        returnFactoryInfo:'发货信息',
        returnFactoryReasonType:'返修原因类型',
        returnFactoryReason:'返修原因',
        backTimestamp:'退回日期',
        returnFactoryQty:'返修数量',
        returningFactoryQty:'返修中数量',
        returningFactory:'返修中',
        returnFactorySuccessful:'返修完成',
        returnFactoryTimestampPlan:'计划发出时间',
        returnFactoryCourierNo:'物流单号',
        returnFactoryCourierType:'物流公司',
        returnFactoryTimestampPlan2:'计划发货日期',
        returnFactoryTimestampReal:'实际发货日期',
        returnFactoryCourierNo2:'发货物流单号',
        returnFactoryCourierType2:'发货物流公司',
        returnFactoryCourierStatus:'发货物流状态',
        selectReturnFactoryProduct:'请选择返修产品',
        returnFactoryRecord:'返修记录',
        returnFactoryNumber:'返修单号',
        returnFactoryInventoryCode:'返修物料编码',
        exceedingReturnFactoryQty:'超出可返修数量',

        initial:'初始',
        returning:'退货中',
        exchangeProgress:'换货中',
        returnSuccessful:'退货成功',
        exchangeSuccessful:'换货成功',

        baseInfo:'基础信息',
        returnInfo:'退货信息',
        exchangeInfo:'换货信息',
        orderLineInfo:'订单行信息',
        bomCategory:'Bom类别',
        bom2:'Bom换货物料编码',
        bom:'Bom',
        bike:'整车',
        swapType:'换货类型',
        warehouse:'仓库',
        inventoryRemark:'通用物料备注',
        swapInventoryCode:'换货物料编码',
        swapInventory:'换货仓库',
        swapQuantity:'换货数量',
        exceedingQuantity:'超出可退数量！',
        exceedingDiscount:'超出订单折扣金额，请重新填写！',
        seeFile:'查看附件',
        selectProduct:'请选择退货产品',
        imgLimit:'上传图片不能超过5M！',
        imgLimit2:'图片格式不正确，请上传.jpg/.jpeg文件！',
        uploadDesc:'最多上传3张图片，单张图片不能超过5M，暂时仅支持 .jpg/.jpeg 格式图片',
        uploadFail:'上传图片失败！',
        courierStatus:'物流状态',
    },
    product:{
        sku:'SKU',
        inventoryCode:'物料编码',
        chineseName:'产品名称(中文)',
        englishName:'产品名称',
        germanName:'产品名称(德文)',
        franceName:'产品名称(法文)',
        torque:'扭矩',
        inventory:'库存',
        frame:'车架',
        createDate:'创建日期',
        model_year:'上市年份',
        model:'车型',
        frameSize:'尺寸',
        frameColor:'颜色',
        unitWeight:'重量',
        weightUomCode:'重量单位',
        hfVersion:'版本',

        addProduct:'新增产品',
        editProduct:'编辑产品',
        skuTip:'SKU不能为空！',
        inventoryCodeTip:'物料编码不能为空！',
        englishNameTip:'产品名称(英文)不能为空！',
        torqueTip:'扭矩不能为空！',
        frameTip:'车架不能为空！',

        sync:'同步',
        syncTip:'点击同步，更新产品信息到历史订单',
        warehouse:'仓库',
        delConfirmTip:'是否确认删除？',

        categoryName:'类型名称',
        inventoryItemStatusCode:'状态',
        syncERPToOrder:'ERP同步至订单',
        success:'成功',
        fail:'失败',
        notSync:'未同步',
        schedulingQuantity:'计划出库数量',


        torqueSizeColorWeight:'扭矩/车架尺寸/颜色/重量',
        modelYearModel:'上市年份/车型',

        exportFile:"导出Excel",
        productData:"产品数据",
        brandName:'品牌名称',
        partName:'零件名称',
    },
    customer:{
        customerName:'名称(OMS维护)',
        customerName2:'名称2',
        customerType:'类型',
        customerEmail:'邮箱',
        customerPhone:'电话',
        customerCountry:'国家',
        customerState:'州',
        customerCity:'城市',
        customerAddr:'地址',
        createDate:'创建日期',
        zip:'邮编',
        billingAddress:'发票地址',
        addressType:'地址类型',
        defaultAddress:'默认地址',
        customerAddr2:'地址2',
        invoiceAddress:'发票地址',
        source:'客户来源(ERP维护)',
        customerErpCode:'ERP客户编码',
        contactName:'公司名称/客户名称',
        contactName2:'客户名称2',
        contactNameNew:'客户名称(OMS Maintenance)',

        user:'用户',
        dealer:'经销商',
        addCustomer:'新建客户',
        syncERPChannels:'同步ERP订单来源',

        sex:'性别',
        male:'男',
        female:'女',
        unknown:'未知'
    },
    dealer:{
        companyName:'公司名称',
        contactPerson:'联系人',
        street:'街道',
        houseNumber:'门牌号',
        zip:'邮编',
        location:'位置',
        country:'国家',
        city:'城市',
        phone:'电话',
        mobile:'移动电话',
        email:'邮箱',
        website:'网站',
        storeAddress:'店铺店址',
        deliveryAddress:'收货地址',
        salesAreaApprox:'销售面积',
        branches:'分支',
        branchesReamrk:'分支备注',
        serviceWorkshop:'是否服务车间',
        foundation:'成立年份',
        portfolio:'投资TOP品牌',
        copySuccessFul:'复制成功!',
        copyTip:'链接有效期为7天，请提醒经销商及时填信息!',
        erpCode:'ERP编码',
        selectSource:'请选择渠道来源',
        qrcode:'生成二维码',
        editErpCode:'编辑ERP Code',

        copyLinkTipTitle:"请提醒经销商",
        btnTip:'1. 链接有效期为7天， 请在有效期内打开链接填写信息。',
        btnTip2:'2. 表单提交成功后，链接也会立即失效。',
        qrcodeTip:'截图分享给经销商，扫码填写表单',
        qrcodeTip2:'二维码有效期为7天'
    },
    saleGoal:{
        country:'国家',
        countryCode:'国家代码',
        year:'年份',
        goal:'目标',
        createGoal:'新建目标',
        goalSaleNumber:'目标销量',
        orderChannelType:'订单渠道类型',
        goalType:'目标类型',
        productType:'产品类型',
        productModelYear:'车型',
        month:'月份',
        year:'年份',

        monthGoalNumber:'各月份目标销量',
        yearSummary:'年度销量汇总',
        b2c:'B2C',
        b2b:'B2B',
        sales:'SALES',
        bike:'BIKE',
        all:'全部',
        my23:'MY23',
        my24:'MY24',

        Jan:'一月',
        Feb:'二月',
        March:'三月',
        April:'四月',
        May:'五月',
        June:'六月',
        July:'七月',
        Aug:'八月',
        Sep:'九月',
        Oct:'十月',
        Nov:'十一月',
        Dec:'十二月',

        inputGoalSaleNumber:'请输入目标销量'
    },
    financial:{
        orderStartDate:'订单开始日期',
        orderEndDate:'订单结束日期',
        invoiceStartDate:'发票开始日期',
        invoiceEndDate:'发票结束日期',
        updateStartDate:'执行开始日期',
        updateEndDate:'执行结束日期',

        invoiceTimestamp:'发票日期',
        financeCheckTime:'执行日期',

        updateOrderStatus:'更新订单状态',
        updatePaymentDate:'更新付款日期',
        payTimestamp:'付款日期',

        year:'年份',
        month:'月份',
        recordTimestamp:'日期',
        amountIn:'入款',
        amountOut:'出款',
        amountTotal:'现金流合计',
        enterCashFlow:'录入现金流',
        account:'应付',
        enterAccount:'录入应付',

        priceListName:'价目表名称',
        syncERPPrice:'同步ERP价目表',
        selectCustomer:'选择渠道来源',

        syncTip:'如果渠道没有同步，需要先同步渠道'
    },
    log:{
        orderNo:'订单编号',
        orderName:'订单编号',
        sku:'SKU',
        source:'订单来源',
        inventoryCode:'物料编码',
        message:'报错描述',
        status:'状态',
        createDate:'创建日期',
        updateDate:'更新日期',
        syncType:'类别',
        syncTimestamp:'同步日期',

        success:'成功',
        error:'错误',
    },
    config:{
        vatNo:'增值税编号',
        enable:'是否启用',
        enable2:'启用',
        disable:'禁用',
        customerType:'客户类型',
        dealerOverdueEmail:'邮箱',
        recipientEmail:'收件人邮箱',
        CCRecipientEmail:'抄送人邮箱',
        dealerOverdueEmailConfig:'经销商逾期邮箱配置',
        recipientEmailConfig:'KPI日报收件人邮箱配置',
        CCRecipientEmailConfig:'KPI日报抄送人邮箱配置',
    },
    dataBoard:{
        dataStatistics:'数据统计',
        vehicleSales:'整车销量',
        factoryShipmentVolume:'工厂出货量',
        orderQuantity:'订单总数',
        bikeQuantity:'整车数量',
        returnQuantity:'整车退货数量',
        cancelQuantity:'整车取消数量',
        shipmentQuantity:'整车发货数量',
        timelyDeliveryRate:'及时交付率',
        outboundBike:'已出库整车',
        paidButUnshippedBike:'已付款未发货整车',
        unpaidBike:'未付款整车',
        fullRefundBike:'全额退款整车',
        pendingPayBike:'未付款未发货整车',
        overdueAndUnpaidBike:'超期未付款未发货整车',
        dataStatistics:'数据统计',
        paymentStatistics:'付款统计',
        confirm:'确定',
        planShipDate:'预计发货日期',
        realShipDate:'实际发货日期',
        overdueDay:'超期15天',
        category:'产品类别',
        within3:'3天内',
        within7:'7天内',
        over7:'超过7天',
        totalEBike:'车辆总数',
        past7days:'默认展示过去7天的数据',
        deliveryRateDetails:'及时交付率明细',
        unscheduledOrder:'未排单'
    },
    setting:{
        menu:'菜单页面',
        role:'角色',
        user:'用户',
        btnAuth:'权限',
        addButton:'新增按钮',
        saveSetting:'保存设置',
        assignRole:'分配角色',
        menuName:'菜单名称',
        addMenu:'新增菜单',
        saveMenuSetting:'保存',

        menuType:'类型',
        btnName:'按钮名称',
        menuParent:'所属菜单父级',
        menuIndex:'所属菜单序号',
        menuCategory:'新增项类别',
        component:'组件',
        saveSettingMsg:'是否确认保存？',
        assignRoleMsg:'请选择角色',
        assignUserMsg:'请选择用户',

        email:'邮箱',
        emailMsg:'邮箱不能为空！',
        emailFormat:'邮箱格式不正确！',

        countryCode:'国家编码',
        countryLocal:'国家本地化名称',
        inputMsg:'请输入信息',

        loginMsg:'保存成功，请用户退出后重新登录',
        loginMsg2:'分配角色成功，请用户退出后重新登录',
        loginMsg3:'删除角色成功，请用户退出后重新登录',

        menuName:'菜单名称',
        menuUrl:'菜单路由',
        menuParent:'父级菜单',
        parentMenuIndex:'父级菜单索引',
        menuIndex:'菜单索引',
        menuAuth:'菜单权限',
        rootMenu:'根菜单',
        edit:'编辑',
        delete:'删除',
        countryCodeEditMsg:"国家本地化名称，车架号，物流三个字段是用于开票本地化设置",

        order:{
            createOrder:'创建订单',
            shippingApply:'发货申请',
            shoppingRecord:'发货记录',
            viewsLogistics:'查看物流',
            refundRecord:'退款记录',
            downloadInvoice:'下载发票',
            addOrderLines:'添加订单行',
            syncToERP:'同步到ERP',
            deleteOrder:'删除订单',
            paid:'支付',
            sendInvoice:'发送发票',
            viewVersion:'查看订单行版本',
            editLine:'编辑订单行',
            deleteLine:'删除订单行',
            cancelSO:'取消SO',
            cancelSORecord:'取消SO记录',
            returnExchange:'退换货',
            returnExchangeRecord:'退换货记录',
            returnFactoryRecord:'返修记录',
            reissueRecord:'补寄记录',
            cancelLineSO:'取消行SO',
            editOrder:'编辑订单',
            partialPay:'部分付款',
            returnFactory:'返修',
            deleteInvoice:'删除发票',
            preInvoiceNo:'预开发票号',

            obligation:'待付款',
            cancelOrder:'取消',
            refundOrder:'退款',
            riskValidate:'风险验证',

            exportEBike:'导出订单',
            exportOrder:'导出订单车辆',
            exportCustomer:'导出订单客户',

            sendConfirmation:'发送确实书',
            downloadConfirmation:'下载确认书',
            syncNewPrice:'同步最新单价',
        },
        product:{
            syncERPToOrder:'ERP同步至订单',
            exportProduct:'导出Excel',
        },
        customer:{
            syncERPCustomer:'同步ERP客户',
            addCustomer:'新增客户',
            editCustomer:'编辑客户',
            deleteCustomer:'删除客户'
        },
        invoice:{
            create2CInvoice:'创建2C发票',
            create2BInvoice:'创建2B发票',
            downloadInvoice:'下载发票',
            reGenerateInvoice:'重新生成发票',
            sendInvoice:'发送发票',
            deleteInvoice:'删除发票',
        },
        error:{

        },
        channelMap:{
            syncERPChannels:'同步来源'
        },
        config:{
            add:'新增',
            edit:'编辑',
            delete:'删除',
            syncERPChannels:'同步来源',
            addRecipientEmail:'新增收件人邮箱',
            addCCRecipientEmail:'新增抄送人邮箱',
            deleteRecipientEmail:'删除收件人邮箱',
            deleteCCRecipientEmail:'删除抄送人邮箱',
        },
        sysSetting:{
            addButton:'新增按钮',
            saveSetting:'保存设置',
            assignRole:'分配角色',
            addEmail:'新增邮箱',
            deleteEmail:'删除邮箱'
        },
        stock:{
            signFor:'签收',
            edit:'编辑',
            detail:'详情',
            download:'下载',
            send:'发送',
            cancel:'取消出库',
            cancelReturn:'取消退货',
            cancelSwap:'取消换货',
            cancelReissue:'取消补寄',
            exportShipment:'导出出库单'
        },
        dealer:{
            shareLink:'分享链接',
            edit:'编辑',
            preview:'预览文件',
        },
        saleGoal:{
            createDealerGoal:'新建经销商目标',
            editDealerGoal:'编辑经销商目标',
            createSaleGoal:'新建车架销售目标',
            editSaleGoal:'编辑车架销售目标',
        },
        financial:{
            updateOrderStatus:'更新订单状态',
            updatePaymentDate:'更新付款日期',
            enterCashFlow:'录入现金流',
            editCashFlow:'编辑现金流',
            enterPayable:'录入应付',
            editPayable:'编辑应付',
            syncERPPrice:'同步ERP价目表'
        },
        returnOrder:{
            createReturnOrder:'创建退货杂单',
        }
    },
}