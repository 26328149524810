/*
 * @Author: caizexin caizexin@kjgb.net
 * @Date: 2023-07-08 17:19:44
 */
import { createApp } from 'vue'
import { createPinia } from 'pinia';
import './style.css'
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import App from './App.vue';
import '@arco-design/web-vue/dist/arco.css';
import '@arco-design/web-vue/dist/arco.less' // 主题色设置
import Router from './router.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import i18n from '@/locale/index.js';

NProgress.inc(0.4)
NProgress.configure({ easing: 'ease', speed: 600, showSpinner: false})

const app = createApp(App)
const PiniaStore = createPinia()

// 注册组件
Object.keys(ArcoVueIcon).forEach(key => {
    app.component(key, ArcoVueIcon[key])
})
// 添加到全局
app.config.globalProperties.$arcoVueIcon = ArcoVueIcon

app.use(Router)
app.use(PiniaStore)
app.use(i18n)
app.use(ArcoVue);

app.mount('#app')

Router.beforeEach((to, from, next) => {
    NProgress.start();
    if(to.path === '/Login') {
        next() 
    } else {
        if(to.meta.requiresAuth && !sessionStorage.getItem('dealerInfo')) { //未登录，则路由定向到 登录路由
            next({ path: '/Login' })
        }else { //不需要登录验证，或者已经登录成功
            next() 
        }
    }
});
Router.afterEach(() => {
    NProgress.done();
});