<template>
    <div class="contanier">
        <div class="head">
            <Header></Header>
        </div>
        
        <div class="content">
            <div class="form_header">
                <img class="back" src="@/assets/image/arrow_left.svg" alt="" width="30" @click="back">
                <div class="title">{{ $t('becomeDealer.title') }}</div>
                <div class="text">{{ $t('becomeDealer.text') }}</div>
            </div>

            <div class="form form1">
                <div class="subTitle">{{ $t('becomeDealer.companyAddress')}}</div>
                <a-form
                    :model="form"
                    layout="vertical"
                    :style="{marginTop: '10px' }"
                    :size="size"
                >
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.companyName')" :required="true" style="display:inline-block;margin-right:20px;width:290px">
                            <a-input v-model="form.company_name" allow-clear max-length="200" :show-word-limit="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.shopName')" :required="true" style="display:inline-block;margin-right:20px;width:290px">
                            <a-input v-model="form.shop_name" allow-clear max-length="100" :show-word-limit="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.contactPerson')" :required="true" style="display:inline-block;width:300px">
                            <a-input v-model="form.contact_person" allow-clear max-length="200" :show-word-limit="true"/>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.street')" :required="true" style="display:inline-block;margin-right:20px;width:650px">
                            <a-input v-model="form.street" allow-clear max-length="200" :show-word-limit="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.houseNumber')" style="display:inline-block;width:250px">
                            <a-input v-model="form.house_number" allow-clear max-length="100" :show-word-limit="true"/>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.plz')" :required="true"  style="display:inline-block;margin-right:20px;width:250px">
                            <a-input v-model="form.zip" allow-clear max-length="100" :show-word-limit="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.ort')" :required="true" style="display:inline-block;width:650px">
                            <a-input v-model="form.city" allow-clear max-length="100" :show-word-limit="true"/>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.country')" :required="true" :style="{width:'920px'}" style="display:inline-block;margin-right:20px;width:290px">
                            <a-select allow-clear @change="changeSelect">
                                <a-option v-for="(item,index) in countryOptions" :key="index" :value="item.value">{{ item.label }}</a-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.vatNo')" style="display:inline-block;margin-right:20px;width:290px">
                            <a-input v-model="form.vat_no" allow-clear max-length="50" :show-word-limit="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.bico')" :required="true" style="display:inline-block;width:300px">
                            <a-select v-model="form.bico" allow-clear>
                                <a-option value="YES">{{ $t('becomeDealer.yes') }}</a-option>
                                <a-option value="NO">{{ $t('becomeDealer.no') }}</a-option>
                            </a-select>
                        </a-form-item>
                    </div>

                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.telephone')" :required="true" style="display:inline-block;margin-right:20px;width:450px">
                            <a-input v-model="form.phone" allow-clear max-length="50" :show-word-limit="true" :hide-button="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.mobile')" style="display:inline-block;width:450px">
                            <a-input v-model="form.mobile" allow-clear max-length="50" :show-word-limit="true"/>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.email')" :required="true" style="display:inline-block;margin-right:20px;width:450px">
                            <a-input v-model="form.email" allow-clear max-length="50" :show-word-limit="true" @change="vaildEmail('email')"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.website')" style="display:inline-block;width:450px">
                            <a-input v-model="form.website" allow-clear max-length="100" :show-word-limit="true"/>
                        </a-form-item>
                    </div>
                </a-form>
            </div>

            <div class="form form2">
                <div class="subTitle">{{ $t('becomeDealer.storeAddress')}}</div>
                <a-form
                    :model="form"
                    layout="vertical"
                    :style="{marginTop: '10px' }"
                    :size="size"
                >
                    <a-form-item>
                        <a-radio-group v-model="storeType" @change="changeStoreRadio">
                            <a-radio value="1" style="width:500px">{{ $t('becomeDealer.sameCompanyAddress') }}</a-radio>
                            <a-radio value="2">{{ $t('becomeDealer.severalAddresses') }}</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.street')" :required="true" style="display:inline-block;margin-right:20px;width:650px">
                            <a-input v-model="form.store_address" :disabled="formDisabled.storeAddressDisabled" allow-clear max-length="200" :show-word-limit="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.houseNumber')" style="display:inline-block;width:250px">
                            <a-input v-model="form.store_house_number" :disabled="formDisabled.storeHouseNumberDisabled" allow-clear max-length="100" :show-word-limit="true"/>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.plz')" :required="true" style="display:inline-block;margin-right:20px;width:250px">
                            <a-input v-model="form.store_zip" :disabled="formDisabled.storeZipDisabled" allow-clear max-length="100" :show-word-limit="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.ort')" :required="true"  style="display:inline-block;width:650px">
                            <a-input v-model="form.store_city" :disabled="formDisabled.storeCityDisabled"  allow-clear max-length="100" :show-word-limit="true"/>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.telephone')" :required="true" style="display:inline-block;margin-right:20px;width:450px">
                            <a-input v-model="form.store_phone" :disabled="formDisabled.storePhoneDisabled"  allow-clear max-length="50" :show-word-limit="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.email')" :required="true" style="display:inline-block;width:450px">
                            <a-input v-model="form.store_email" :disabled="formDisabled.storeEmailDisabled"  allow-clear max-length="50" :show-word-limit="true" @change="vaildEmail('store_email')"/>
                        </a-form-item>
                    </div>
                </a-form>
            </div>

            <div class="form form3">
                <div class="subTitle">{{ $t('becomeDealer.deliveryAddress')}}</div>
                <a-form
                    :model="form"
                    layout="vertical"
                    :style="{marginTop: '10px' }"
                    :size="size"
                >
                    <a-form-item>
                        <a-radio-group v-model="deliveryType" @change="changeDeliveryRadio">
                            <a-radio value="1" style="width:230px">{{ $t('becomeDealer.sameCompanyAddress') }}</a-radio>
                            <a-radio value="2" style="width:285px">{{ $t('becomeDealer.sameStoreAddress') }}</a-radio>
                            <a-radio value="3">{{ $t('becomeDealer.severalAddresses') }}</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.street')" :required="true" style="display:inline-block;margin-right:20px;width:650px">
                            <a-input v-model="form.delivery_address" :disabled="formDisabled2.deliveryAddressDisabled" allow-clear max-length="200" :show-word-limit="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.houseNumber')" style="display:inline-block;width:250px">
                            <a-input v-model="form.delivery_house_number" :disabled="formDisabled2.deliveryHouseNumberDisabled" allow-clear max-length="100" :show-word-limit="true"/>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.plz')" :required="true" style="display:inline-block;margin-right:20px;width:250px">
                            <a-input v-model="form.delivery_zip" :disabled="formDisabled2.deliveryZipDisabled" allow-clear max-length="100" :show-word-limit="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.ort')" :required="true"  style="display:inline-block;width:650px">
                            <a-input v-model="form.delivery_city" :disabled="formDisabled2.deliveryCityDisabled" allow-clear max-length="100" :show-word-limit="true"/>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.telephone')" :required="true" style="display:inline-block;margin-right:20px;width:450px">
                            <a-input v-model="form.delivery_phone" :disabled="formDisabled2.deliveryPhoneDisabled" allow-clear max-length="50" :show-word-limit="true"/>
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.email')" :required="true" style="display:inline-block;width:450px">
                            <a-input v-model="form.delivery_email" :disabled="formDisabled2.deliveryEmailDisabled" allow-clear max-length="50" :show-word-limit="true" @change="vaildEmail('delivery_email')"/>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.salesAreaApprox')" style="display:inline-block;margin-right:20px;width:300px">
                            <a-input v-model="form.sales_area_approx" allow-clear max-length="50" style="margin-right:5px" /> m²
                        </a-form-item>
                        <a-form-item :label="$t('becomeDealer.branches')" :required="true" style="display:inline-block;width:600px">
                            <a-radio-group v-model="form.branches" @change="changeBranch"> 
                                <a-radio value="NO" style="width:150px">{{ $t('becomeDealer.no') }}</a-radio>
                                <a-radio value="YES" style="">{{ $t('becomeDealer.yesHave') }}</a-radio>
                            </a-radio-group>
                            <a-input-number class="branches_remark" v-model="form.branches_remark" :disabled="form.branches !== 'YES'" allow-clear max-length="50" :hide-button="true" style="display:inline-block;margin-right:10px;width:120px"/> <span style="color:#777">{{ $t('becomeDealer.branches2') }}</span>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.serviceWorkshop')" :required="true">
                            <a-radio-group v-model="form.service_workshop">
                                <a-radio value="NO" style="width:100px">{{ $t('becomeDealer.no') }}</a-radio>
                                <a-radio value="YES" style="">{{ $t('becomeDealer.yes') }}</a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.foundation')" >
                            <a-input v-model="form.foundation" allow-clear max-length="50" style="width:275px"/>
                        </a-form-item>
                    </div>
                    <div class="form_item_block">
                        <a-form-item :label="$t('becomeDealer.top5Brands')" >
                            <a-input v-model="form.portfolio" allow-clear max-length="200" :show-word-limit="true" style="width:920px"/>
                        </a-form-item>
                    </div>
                </a-form>
            </div>

            <a-checkbox v-model="checked" >{{ $t('becomeDealer.checkText')}}</a-checkbox>
            <a-button type="primary" class="sendNow" :class="{'sendNowDisabled':!checked}" :disabled="!checked" @click="beforeSentNow">{{ $t('becomeDealer.sendNow') }}</a-button>
        </div>

        <!-- 滑块验证弹窗 -->
        <Verify
            class="verify"
            ref="verifyRef"
            mode="pop" 
            captchaType="blockPuzzle"
            :imgSize="{ width: '408px', height: '155px' }"
            @success="success" 
        ></Verify>
       
    </div>
</template>

<script setup>
    import { onMounted, reactive, ref, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { LoginApi } from '@/api/LoginApi.js';
    import { Message, Modal } from '@arco-design/web-vue';
    import { useI18n } from 'vue-i18n';
    import Header from './component/Header.vue';
    import Verify from "@/components/verifition/Verify";


    const router = useRouter()
    const { t } = useI18n()

    const language = localStorage.getItem('dealerLocale')? ref(localStorage.getItem('dealerLocale')) : ref('en')
   
    const checked = ref(false)
    const formDisabled = reactive({
        storeAddressDisabled:false,
        storeCityDisabled:false,
        storeHouseNumberDisabled:false,
        storeZipDisabled:false,
        storePhoneDisabled:false,
        storeEmailDisabled:false,
    })
    const formDisabled2 = reactive({
        deliveryAddressDisabled:false,
        deliveryCityDisabled:false,
        deliveryHouseNumberDisabled:false,
        deliveryZipDisabled:false,
        deliveryPhoneDisabled:false,
        deliveryEmailDisabled:false,
    })
    const form = reactive({
        company_name:'',
        contact_person:'',
        country:'',
        country_code:'',
        street:'',
        house_number:'',
        zip:'',
        city:'',
        phone:'',
        mobile:'',
        email:'',
        website:'',
        vat_no:'',
        shop_name:'',
        bico:'',

        store_address:'',
        store_house_number:'',
        store_city:'',
        store_zip:'',
        store_phone:'',
        store_email:'',

        delivery_address:'',
        delivery_house_number:'',
        delivery_city:'',
        delivery_zip:'',
        delivery_phone:'',
        delivery_email:'',
        sales_area_approx:'',
        branches:'',
        branches_remark:'',
        service_workshop:'',
        foundation:'',
        portfolio:'',

        captcha_verification:'',
    })
    const emailIsHas = ref(false)
    const storeType = ref('')
    const deliveryType = ref('')
    const countryOptions = ref([])
    const verifyRef = ref(null)

    const back = ()=>{
        router.push('/Login')
    }

    const changeSelect = (val)=>{
        let item = countryOptions.value.find(i=> i.value == val) 
        form.country = item.label
        form.country_code = val
    }

    const changeStoreRadio = (val)=>{
        for(let key in formDisabled){
            formDisabled[key] = storeType.value == 1
        }

        let obj = {
            street:'',
            house_number:'',
            city:'',
            zip:'',
            phone:'',
            email:'',
        }

        for(let key in obj){
            if(key == 'street') form.store_address = storeType.value == 1? form.street : ''
            else form[`store_${key}`] = storeType.value == 1? form[key] : ''
        }
        
    }

    const changeDeliveryRadio = ()=>{
        for(let key in formDisabled2){
            formDisabled2[key] = deliveryType.value != 3   
        }

        let obj = {
            street:'',
            house_number:'',
            city:'',
            zip:'',
            phone:'',
            email:'',
        }

        for(let key in obj){
            if(deliveryType.value == 1){
                if(key == 'street') form.delivery_address = form.street
                else form[`delivery_${key}`] = form[key]
            }else if(deliveryType.value == 2){
                if(key == 'street') form.delivery_address = form.store_address
                else form[`delivery_${key}`] = form[`store_${key}`]
            }else{
                if(key == 'street') form.delivery_address = ''
                else form[`delivery_${key}`] = ''
            }
        }
    }

    const changeBranch = ()=>{
        form.branches_remark = ''
    }

    // 邮箱格式校验
    const vaildEmail = (type)=>{
        let pattern = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,}$/
        let bool = type == 'email'? pattern.test(form.email) : type == 'store_email'? pattern.test(form.store_email) : pattern.test(form.delivery_email)
        let email = type == 'email'? form.email : type == 'store_email'? form.store_email : form.delivery_email

        if(!bool && email){
            Modal.error({
                title: t('base.tip'),
                titleAlign:'start',
                hideCancel:true,
                content: type == 'email'?  t('login.error_email') : type == 'store_email'? t('login.store_error_email') : t('login.store_error_email'),
                okText:t('base.ok')
            })
        }
    }

    // 提交校验
    const vaild = ()=>{
        if(!form.company_name){  // 公司地址
            Message.error(t('becomeDealer.companyName') + ' '+ t('base.noEmpty'))
            return false
        }else if(!form.contact_person){
            Message.error(t('becomeDealer.contactPerson') + ' '+ t('base.noEmpty'))
            return false
        }else if(!form.street){
            Message.error(t('becomeDealer.street') + ' '+ t('base.noEmpty'))
            return false
        }else if(!form.zip){
            Message.error(t('becomeDealer.plz') + ' '+ t('base.noEmpty'))
            return false
        }else if(!form.city){
            Message.error(t('becomeDealer.ort') + ' '+ t('base.noEmpty'))
            return false
        }else if(!form.country){
            Message.error(t('becomeDealer.country') + ' '+ t('base.noEmpty'))
            return false
        }else if(!form.bico){
            Message.error(t('becomeDealer.bico') + ' '+ t('base.noEmpty'))
            return false
        }else if(!form.shop_name){
            Message.error(t('becomeDealer.shopName') + ' '+ t('base.noEmpty'))
            return false
        }else if(!form.phone){
            Message.error(t('becomeDealer.telephone') + ' '+ t('base.noEmpty'))
            return false
        }else if(!form.email){
            Message.error(t('becomeDealer.email') + ' '+ t('base.noEmpty'))
            return false
        }else if(!form.store_address){  // 店铺地址
            Message.error(t('becomeDealer.storeStreetEmpty'))
            return false
        }else if(!form.store_zip){
            Message.error(t('becomeDealer.storePlzEmpty'))
            return false
        }else if(!form.store_city){
            Message.error(t('becomeDealer.storeOrtEmpty'))
            return false
        }else if(!form.store_phone){
            Message.error(t('becomeDealer.storePhoneEmpty'))
            return false
        }else if(!form.store_email){
            Message.error(t('becomeDealer.storeEmailEmpty'))
            return false
        }else if(!form.delivery_address){ // 发货地址
            Message.error(t('becomeDealer.deliveryStreetEmpty'))
            return false
        }else if(!form.delivery_city){
            Message.error(t('becomeDealer.deliveryOrtEmpty'))
            return false
        }else if(!form.delivery_phone){
            Message.error(t('becomeDealer.deliveryPhoneEmpty'))
            return false
        }else if(!form.delivery_email){
            Message.error(t('becomeDealer.deliveryEmailEmpty'))
            return false
        }else if(!form.branches){
            Message.error(t('becomeDealer.deliveryBranchEmpty'))
            return false
        }else if(!form.service_workshop){
            Message.error(t('becomeDealer.deliveryWorkshopEmpty'))
            return false
        }

        return true
    } 
    
    const beforeSentNow = ()=>{
        if(!vaild()) return
        
        if(form.captcha_verification == ''){
            verifyRef.value.show()
            return
        }
    }


    // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
    const success = (params)=>{ 
        console.log(params,'params')
        form.captcha_verification = params.captchaVerification
        sendNow()
    }
    
    // 注册
    const sendNow = async ()=>{
        if(!vaild()) return

        form.license_flag = checked.value
        let res = await LoginApi.getInstance().saveBecomeDealer(form)
        if(res.code == 0){
            Modal.success({
                title: t('becomeDealer.submitSuccessful'),
                titleAlign:'start',
                hideCancel:true,
                content: t('becomeDealer.waitAuditTip'),
                okText:t('base.ok'),
                onOk:()=>{
                    router.replace('/Login')
                }
            })
        }else{
            form.captcha_verification = ''
            Message.error(res.msg)
        }
    }

    // 获取国家
    const getCountry = async()=>{
        let res = await LoginApi.getInstance().getCountry({dealer_flag:1})
        if(res.code == 0){
            countryOptions.value = []
            res.data.length > 0 && res.data.forEach(i=>{
                countryOptions.value.push({
                    label:i.country,
                    value:i.country_code,
                    country_local: i.country_local
                })
            })
        }else{
            Message.error(res.msg)
        }
    }

    onMounted(()=>{
        getCountry()
    })


</script>

<style lang="less" scoped>
.contanier{
    width: 100%;
    background: #F5F7F8;
}
.head{
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 70px;
    z-index: 999;

}
.content{
    margin: 70px auto 0;
    padding-top: 30px;
    width: 1000px;
    text-align: center;

    .form_header{
        width: 1000px;
        margin: 0 auto;

        .back{
            float: left;
            margin-left: -5px;
            padding: 5px;
            border-radius: 2px;
            cursor: pointer;
            background: #f7f5ff;
        }

        .title{
            font-size: 30px;
            font-weight: 600;
            color: #000;
        }

        .text{
            margin-top: 20px;
            font-size: 16px;
            font-weight: 400;
            color: #000;
        }
    }

    .form{
        margin-top: 20px;
        padding: 30px 40px;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.12), 0px 15px 35px 0px rgba(60, 66, 87, 0.08);;
    
        .subTitle{
            text-align: left;
            font-size: 20px;
            font-weight: 700;
        }

        /deep/ .arco-form-item-label-col > .arco-form-item-label{
            font-size: 15px;
            font-weight: 600;
            color: #3F3F3F;
        }

        /deep/ .arco-radio-label{
            font-size: 15px;
            color: #777;
        }

       
    }

    .form3{
        .form_item_block{
            /deep/ .arco-radio-group{
                // margin-left: -30px;
                // width: 300px;
                text-align: left;
            }
        }

        .branches_remark{
            /deep/ .arco-input{
                width: 80px;
            }
        }
        
    }

    /deep/ .arco-checkbox-label{
        margin-top:20px;
        font-size: 16px;
        font-weight: 400;
        color: #000;
        text-align: left;
    } 

    .sendNow{
        margin-top: 20px;
        margin-bottom: 30px;
        width: 300px;
        height: 60px;
        background: #000;
        border-radius: 50px;
        font-size: 16px;
        font-weight: 500;
    }

    .sendNowDisabled{
        background: #999;
    }
    
}
</style>