/*
 * @Author: caizexin caizexin@kjgb.net
 * @Date: 2022-11-22 17:11:27
 */
import { Data } from '@/api/axiosFun.js';

class LoginApi {
    static getInstance() {
        if (!this.instance) this.instance = new LoginApi()
        return this.instance
    }

    // 获取数据
    async getData(url, param, method, dataType) {
        let result = await Data.getInstance().getData(url, param, method, dataType)
        return result
    }

    // 登录
    async login (param){
        let url = `/api/user/login`
        let result = await this.getData(url,param,'POST')
        return result
    }

    // 重置密码
    async resetPassword (param){
        let url = `/api/user/reset/pwd/${param.email}`
        let result = await this.getData(url,param,'POST')
        return result
    }

    // 获取验证码
    async getVaildCode (email,captcha_verification){
        let url = `/api/user/send_captcha?email=${email}&captcha_verification=${captcha_verification}`
        let result = await this.getData(url,{},'GET')
        return result
    }

    // 保存经销商信息
    async saveBecomeDealer (param){
        let url = `/api/dealer/save`
        let result = await this.getData(url,param,'POST')
        return result
    }

    // 获取国家
    async getCountry (param){
        let url = `/api/system/country/query`
        let result = await this.getData(url,param,'POST')
        return result
    }

    // reqGet
    async reqGet (param){
        let url = `/api/captcha/get`
        let result = await this.getData(url,param,'POST')
        return result
    }

    // reqCheck
    async reqCheck (param){
        let url = `/api/captcha/check`
        let result = await this.getData(url,param,'POST')
        return result
    }
}

export {
    LoginApi
}