<!--
 * @Author: caizexin caizexin@kjgb.net
 * @Date: 2023-07-08 17:19:44
-->
<template>
  <a-config-provider :locale="locale">
    <router-view></router-view>
  </a-config-provider>
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import enUS from '@arco-design/web-vue/es/locale/lang/en-us';
    import zhCN from '@arco-design/web-vue/es/locale/lang/zh-cn';
    
    const locale = ref('') 

    if (localStorage.getItem('locale') && localStorage.getItem('isDealerForm') == null) {
      let cache_locale = localStorage.getItem('locale')
      locale.value = cache_locale == 'zh'? ref(zhCN) : cache_locale == 'en'? ref(enUS) : ref(de)
    } else {
      locale.value = ref(enUS)
    }
</script>

<style scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}

</style>
