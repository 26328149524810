<template>
    <div class="container">
        <div class="content_hd" id="orderList">
            <div class="tab">
                <div 
                    v-for="(item,index) in tabList" 
                    :key="index" 
                    class="tabItem" 
                    :class="{'tabItemActive':tabActive == item.value}"
                    @click="clickTab(item.value)"
                >{{ item.name }}</div>
                <a-button class="resetTableFilter" type="primary" @click="resetTableFilter">{{ $t('order.resetFilter') }}</a-button>
            </div>
        </div>
       

        <!-- Trekking7 -->
        <Table
            ref="tableRef"
            class="ordreList"
            v-if="tabActive == 1"
            :table="trekking7_table"
        >   
            <template v-slot:columns="{ props }">
                <div v-if="props.column.dataIndex == 'inventory'">
                    <a-tag :color="props.record.inventory_val >10? 'green' : props.record.inventory_val <= 10 && props.record.inventory_val >= 1? 'gold' : props.record.inventory_val === null? 'red' : 'gray'">
                        {{props.record.inventory_text}}
                    </a-tag>
                </div>
                <div v-if="props.column.dataIndex == 'quantity'">
                    <a-input-number 
                        v-model="props.record.quantity" 
                        :style="{width:'100px'}"
                        :min="1" 
                        :max="1000" 
                        size="mini"
                        :hide-button="true" 
                        :disabled="props.record.inventory_val == null"
                        allow-clear
                        @change="(value)=>handleChangeTK7(value, props.record)"
                        @input="(value)=>handleInputTK7(value, props.record)"
                    />
                </div>
                <div v-if="props.column.dataIndex == 'standard_price'">
                    {{ props.record.standard_price }} <span v-if="props.record.standard_price !== '-'">€</span>
                </div>
                <div v-if="props.column.dataIndex == 'ek_price'">
                    {{ props.record.ek_price }} <span v-if="props.record.ek_price !== '-'">€</span>
                </div>
                <div v-if="props.column.dataIndex == 'delivery_type'">
                    <a-select 
                        v-model="props.record.delivery_type" 
                        :style="{width:'120px'}" 
                        size="mini" 
                        :placeholder="t('base.select')" 
                        @change="(value)=>changeSelect(value,props.record)"
                    >
                        <a-option value="1">{{ $t('order.standard') }}</a-option>
                        <a-option value="2">{{ $t('order.express') }}</a-option>
                        <a-option value="3">{{ $t('order.collection') }}</a-option>
                    </a-select>
                </div>
            </template>

            <template v-slot:searchFilter="{ props }">
                <div class="searchFilter">
                    <a-space direction="vertical">
                    <a-input :model-value="props.filterValue[0]" style="width:164px" @input="(value)=>props.setFilterValue([value])" />
                    <div class="searchFilter-footer">
                        <a-button size="mini" style="margin-right:10px" @click="props.handleFilterReset">{{ $t('base.reset') }}</a-button>
                        <a-button size="mini" type="primary" @click="props.handleFilterConfirm">{{ $t('base.ok') }}</a-button>
                    </div>
                    </a-space>
                </div>
            </template>
        </Table>

        <!-- Mountain7 -->
        <Table
            ref="tableRef"
            class="ordreList"
            v-if="tabActive == 2"
            :table="mountain7_table"
        >
            <template v-slot:columns="{ props }">
                <div v-if="props.column.dataIndex == 'inventory'">
                    <a-tag :color="props.record.inventory_val >10? 'green' : props.record.inventory_val <= 10 && props.record.inventory_val >= 1? 'gold' : props.record.inventory_val === null? 'red' : 'gray'">
                        {{props.record.inventory_text}}
                    </a-tag>
                </div>
                <div v-if="props.column.dataIndex == 'quantity'">
                    <a-input-number 
                        v-model="props.record.quantity" 
                        :style="{width:'100px'}" 
                        class="input-demo" 
                        :min="1" 
                        :max="1000" 
                        size="mini"
                        :hide-button="true" 
                        :disabled="props.record.inventory_val == null"
                        allow-clear
                        @change="(value)=>handleChangeMT7(value, props.record)"
                        @input="(value)=>handleInputMT7(value, props.record)"
                    />
                </div>
                <div v-if="props.column.dataIndex == 'standard_price'">
                    {{ props.record.standard_price }} <span v-if="props.record.standard_price !== '-'">€</span>
                </div>
                <div v-if="props.column.dataIndex == 'ek_price'">
                    {{ props.record.ek_price }} <span v-if="props.record.ek_price !== '-'">€</span>
                </div>
                <div v-if="props.column.dataIndex == 'delivery_type'">
                    <a-select 
                        v-model="props.record.delivery_type" 
                        :style="{width:'120px'}" 
                        size="mini" 
                        :placeholder="t('base.select')" 
                        @change="(value)=>changeSelect(value,props.record)"
                    >
                        <a-option value="1">{{ $t('order.standard') }}</a-option>
                        <a-option value="2">{{ $t('order.express') }}</a-option>
                        <a-option value="3">{{ $t('order.collection') }}</a-option>
                    </a-select>
                </div>
            </template>
            <template v-slot:searchFilter="{ props }">
                <div class="searchFilter">
                    <a-space direction="vertical">
                    <a-input :model-value="props.filterValue[0]" style="width:164px" @input="(value)=>props.setFilterValue([value])" />
                    <div class="searchFilter-footer">
                        <a-button size="mini" style="margin-right:10px" @click="props.handleFilterReset">{{ $t('base.reset') }}</a-button>
                        <a-button size="mini" type="primary" @click="props.handleFilterConfirm">{{ $t('base.ok') }}</a-button>
                    </div>
                    </a-space>
                </div>
            </template>
        </Table>

        <!-- Mountain 7 Carbon -->
        <Table
            ref="tableRef"
            class="ordreList"
            v-if="tabActive == 3"
            :table="mountain7Carbon_table"
        >
            <template v-slot:columns="{ props }">
                <div v-if="props.column.dataIndex == 'inventory'">
                    <a-tag :color="props.record.inventory_val >10? 'green' : props.record.inventory_val <= 10 && props.record.inventory_val >= 1? 'gold' : props.record.inventory_val === null? 'red' : 'gray'">
                        {{props.record.inventory_text}}
                    </a-tag>
                </div>
                <div v-if="props.column.dataIndex == 'quantity'">
                    <a-input-number 
                        v-model="props.record.quantity" 
                        :style="{width:'100px'}" 
                        class="input-demo" 
                        :min="1" 
                        :max="1000" 
                        size="mini"
                        :hide-button="true" 
                        :disabled="props.record.inventory_val == null"
                        allow-clear
                        @change="(value)=>handleChangeMT7C(value, props.record)"
                        @input="(value)=>handleInputMT7C(value, props.record)"
                    />
                </div>
                <div v-if="props.column.dataIndex == 'standard_price'">
                    {{ props.record.standard_price }} <span v-if="props.record.standard_price !== '-'">€</span>
                </div>
                <div v-if="props.column.dataIndex == 'ek_price'">
                    {{ props.record.ek_price }} <span v-if="props.record.ek_price !== '-'">€</span>
                </div>
                <div v-if="props.column.dataIndex == 'delivery_type'">
                    <a-select 
                        v-model="props.record.delivery_type" 
                        :style="{width:'120px'}" 
                        size="mini"
                        :placeholder="t('base.select')" 
                        @change="(value)=>changeSelect(value,props.record)"
                    >
                        <a-option value="1">{{ $t('order.standard') }}</a-option>
                        <a-option value="2">{{ $t('order.express') }}</a-option>
                        <a-option value="3">{{ $t('order.collection') }}</a-option>
                    </a-select>
                </div>
            </template>
            <template v-slot:searchFilter="{ props }">
                <div class="searchFilter">
                    <a-space direction="vertical">
                    <a-input :model-value="props.filterValue[0]" style="width:164px" @input="(value)=>props.setFilterValue([value])" />
                    <div class="searchFilter-footer">
                        <a-button size="mini" style="margin-right:10px" @click="props.handleFilterReset">{{ $t('base.reset') }}</a-button>
                        <a-button size="mini" type="primary" @click="props.handleFilterConfirm">{{ $t('base.ok') }}</a-button>
                    </div>
                    </a-space>
                </div>
            </template>
        </Table>

        <!-- Trekking 7 MY23 -->
        <Table
            ref="tableRef"
            class="ordreList"
            v-if="tabActive == 4"
            :table="trekking7MY23_table"
        >
            <template v-slot:columns="{ props }">
                <div v-if="props.column.dataIndex == 'inventory'">
                    <a-tag :color="props.record.inventory_val >10? 'green' : props.record.inventory_val <= 10 && props.record.inventory_val >= 1? 'gold' : props.record.inventory_val === null? 'red' : 'gray'">
                        {{props.record.inventory_text}}
                    </a-tag>
                </div>
                <div v-if="props.column.dataIndex == 'quantity'">
                    <a-input-number 
                        v-model="props.record.quantity" 
                        :style="{width:'100px'}" 
                        class="input-demo" 
                        :min="1" 
                        :max="1000" 
                        size="mini"
                        :hide-button="true" 
                        :disabled="props.record.inventory_val == null"
                        allow-clear
                        @change="(value)=>handleChangeTK723(value, props.record)"
                        @input="(value)=>handleInputTK723(value, props.record)"
                    />
                </div>
                <div v-if="props.column.dataIndex == 'standard_price'">
                    {{ props.record.standard_price }} <span v-if="props.record.standard_price !== '-'">€</span>
                </div>
                <div v-if="props.column.dataIndex == 'ek_price'">
                    {{ props.record.ek_price }} <span v-if="props.record.ek_price !== '-'">€</span>
                </div>
                <div v-if="props.column.dataIndex == 'delivery_type'">
                    <a-select 
                        v-model="props.record.delivery_type" 
                        :style="{width:'120px'}" 
                        size="mini"
                        :placeholder="t('base.select')" 
                        @change="(value)=>changeSelect(value,props.record)"
                    >
                        <a-option value="1">{{ $t('order.standard') }}</a-option>
                        <a-option value="2">{{ $t('order.express') }}</a-option>
                        <a-option value="3">{{ $t('order.collection') }}</a-option>
                    </a-select>
                </div>
            </template>
            <template v-slot:searchFilter="{ props }">
                <div class="searchFilter">
                    <a-space direction="vertical">
                    <a-input :model-value="props.filterValue[0]" style="width:164px" @input="(value)=>props.setFilterValue([value])" />
                    <div class="searchFilter-footer">
                        <a-button size="mini" style="margin-right:10px" @click="props.handleFilterReset">{{ $t('base.reset') }}</a-button>
                        <a-button size="mini" type="primary" @click="props.handleFilterConfirm">{{ $t('base.ok') }}</a-button>
                    </div>
                    </a-space>
                </div>
            </template>
        </Table>

        <!-- 页脚 -->
        <div class="summary">
            <div class="sum_block">
                <div class="value">{{ sum_bike }}</div>
                <div class="text">{{ $t('order.numberOfBikes') }}</div>
            </div>
            <div class="sum_block">
                <div class="value">{{ sum_price }} €</div>
                <div class="text">{{ $t('order.total') }}</div>
            </div>
            <div class="sum_block block3">
                <a-button 
                    type="primary" 
                    class="orderNow" 
                    :class="{'orderNowDisabeld':orderNowDisabled}" 
                    :disabled="orderNowDisabled" 
                    @click="orderNow"
                >{{ $t('order.orderNow') }}</a-button>
                <div class="text text3">{{ $t('order.allPrices') }}</div>
            </div>
        </div>

        <!-- 下单确认弹窗 -->
        <a-modal 
            class="sum_dialog"
            v-model:visible="visible" 
            width="80%"
            title-align="start"
            :render-to-body="false"
            :okText="t('order.sendOrder')"
            :cancelText="t('order.cancel')"
            :on-before-ok="handleBeforeOk" 
            @cancel="handleCancel"
        >
            <template #title>{{ $t('order.summaryOrderTitle') }}</template>
            <div>
                <Table
                    :table="summary_table"
                ></Table>          

                <div class="sum_dialog_center">
                    <div class="addNote">
                        <div class="title">{{ $t('order.addNote') }}</div>
                        <a-textarea 
                            v-model="user_note"
                            :placeholder="t('base.enterSomething')" 
                            :max-length="500"
                            :show-word-limit="true"
                            allow-clear
                        />
                    </div>
                    <div class="subTotal">
                        <div class="sum">{{ $t('order.subTotal') }}: {{ subTotal }} €</div>
                        <div class="text">{{ $t('order.allPrices') }}</div>
                        <div class="text">
                            {{ $t('order.accept') }} 
                            <a href="https://hepha.com/de/policies/terms-of-service" target="_blank" class="link">{{ $t('order.term') }}</a>
                        </div>
                        <div class="text">
                            {{ $t('order.and') }} 
                            <a href="https://hepha.com/de/policies/privacy-policy" target="_blank" class="link">{{ $t('order.policy') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>

        <!-- 选择经销商 -->
        <!-- <a-modal 
            class="sum_dialog"
            v-model:visible="selectDealerVisible" 
            width="30%"
            title-align="start"
            draggable
            :mask-closable="false"
            :closable="false"
            :render-to-body="false"
            :okText="t('base.ok')"
            :on-before-ok="handleSelectDealerBeforeOk"
            @cancel="cancelSelect"
        >
            <template #title>{{ $t('order.selectDealer') }}</template>
            <a-select :style="{margin:'20px 0',width:'320px'}"  v-model="dealer_source_id" placeholder="Please select ...">
                <a-option 
                    v-for="(item,index) in dealerOption" 
                    :key="index" 
                    :value="item.value" 
                    :label="item.label" 
                />
            </a-select>
        </a-modal> -->
    </div>
</template>

<script setup>
    import { onMounted, reactive, ref, watch, h } from 'vue';
    import { useRouter } from 'vue-router';
    import { OrderApi } from '@/api/OrderApi.js';
    import { Message, Modal } from '@arco-design/web-vue';
    import { useI18n } from 'vue-i18n';
    import Table from '@/components/NewTable.vue';
    import { multiply, add, subtract, divide } from 'accurate-core';
    import { dateToTimestamp, timestampToDate, getNowDate } from '@/utils/index.js';
    import { IconSearch } from '@arco-design/web-vue/es/icon';
    // import { useLocale } from '@/store/index.js'

    const router = useRouter()
    const { t } = useI18n()
    // const localeStore = useLocale()

    const dealerInfo = ref({}) // 当前登录的经销商信息
    const tabActive = ref(1)
    const tabList = ref([
        {
            name:t('order.Trekking7'),
            value:1
        },
        {
            name:t('order.Mountain7'),
            value:2
        },
        {
            name:t('order.Mountain7Carbon'),
            value:3
        },
        {
            name:t('order.Trekking7MY23'),
            value:4
        }
    ])
    
    const tableRef = ref(null)
    const visible = ref(false)
    // const selectDealerVisible = ref(false)
    // const dealerOption = ref([])
    // const dealer_source_id = ref('')
    const column = ref([
            {
                // title: '数量',
                title:t('order.orderQty'),
                dataIndex: 'quantity',
                key:'quantity',
                align:'left',
                ellipsis:true,
                tooltip:true,
                // fixed:'right',
                width:140,
                slotName:'slot',
            },
            {
                // title: '库存情况',
                title:t('order.availability'),
                dataIndex: 'inventory',
                key:'inventory',
                align:'left',
                ellipsis:true,
                tooltip:true,
                width:180,
                slotName:'slot',
            },
            {
                // title: '车型',
                title:t('order.modelVersion'),
                dataIndex: 'model_version',
                key:'model_version',
                align:'left',
                width:250,
                ellipsis:true,
                tooltip:true,
                sortable: {
                    sortDirections: ['descend'],
                    defaultSortOrder: 'descend',
                },
                filterable: {
                    filters: [],
                    filter: (value, row) => row.model_version.includes(value),
                }
            },
            {
                // title: '车型年份',
                title:t('order.modelYear'),
                dataIndex: 'model_year',
                key:'model_year',
                align:'left',
                ellipsis:true,
                tooltip:true,
                width:150,
                filterable: {
                    filters: [],
                    filter: (value, row) => row.model_year.includes(value),
                }
            },
            {
                // title: '车架类型',
                title:t('order.frame'),
                dataIndex: 'frame_shape',
                key:'frame_shape',
                align:'left',
                ellipsis:true,
                tooltip:true,
                width:150,
                sortable: {
                    sortDirections: ['ascend'],
                },
                filterable: {
                    filters: [],
                    filter: (value, row) => row.frame_shape.includes(value),
                }
            },
            {
                // title: '尺寸',
                title:t('order.size'),
                dataIndex: 'frame_size',
                key:'frame_size',
                align:'left',
                width:120,
                ellipsis:true,
                tooltip:true,
                sortable: {
                    sortDirections: ['ascend']
                },
            },
            {
                // title: '颜色',
                title:t('order.color'),
                dataIndex: 'frame_color',
                key:'frame_color',
                align:'left',
                width:120,
                ellipsis:true,
                tooltip:true,
                sortable: {
                    sortDirections: ['ascend']
                },
                filterable: {
                    filters: [],
                    filter: (value, row) => row.frame_color.includes(value),
                }
            },
            {
                // title: '车辆标准价',
                title:t('order.uvp'),
                dataIndex: 'standard_price',
                key:'standard_price',
                align:'left',
                ellipsis:true,
                tooltip:true,
                width:120,
                slotName:'slot',
                sortable: {
                    sortDirections: ['ascend','descend']
                },
            },
            {
                // title: 'EK售格',
                title:t('order.ek'),
                dataIndex: 'ek_price',
                key:'ek_price',
                align:'left',
                ellipsis:true,
                tooltip:true,
                width:120,
                slotName:'slot',
                sortable: {
                    sortDirections: ['ascend','descend']
                },
            },
            {
                // title: 'SKU',
                title:t('order.projectNumber'),
                dataIndex: 'sku',
                key:'sku',
                align:'left',
                width:200,
                ellipsis:true,
                tooltip:true,
                filterable: {
                    filter: (value, record) => record.sku.includes(value),
                    slotName: 'searchFilter',
                    icon: () => h(IconSearch)
                }
            },
            {
                // title: '交货类型',
                title:t('order.deliveryType'),
                dataIndex: 'delivery_type',
                key:'delivery_type',
                align:'left',
                ellipsis:true,
                tooltip:true,
                // fixed:'right',
                width:160,
                slotName:'slot'
            },
        ])
    const config = ref({
            scroll:{
                x:1000,
                // y:580,
            },
            stripe:false,
            bordered:{wrapper: true, cell: true},
            size:'large',
            loading:false,
            expandable:'',
            expand:false,
            pagination:false,
        })
    const trekking7_table = ref({
        datas:[],
        columns: column.value,
        configs:config.value
    })
    const mountain7_table = ref({
        datas:[],
        columns:column.value,
        configs:config.value
    })
    const mountain7Carbon_table = ref({
        datas:[],
        columns:column.value,
        configs:config.value
    })
    const trekking7MY23_table = ref({
        datas:[],
        columns: column.value,
        configs:config.value
    })

    // 合计表格
    const summary_table = ref({
        datas:[],
        columns:[{
                title: '#',
                dataIndex: 'No',
                key:'No',
                width:'120',
                align:'left',
            },
            {
                // title: 'SKU',
                title:t('order.projectNumber'),
                dataIndex: 'sku',
                key:'sku',
                align:'left',
                width:220,
                ellipsis:true,
                tooltip:true,
            },
            {
                // title: '车型年份',
                title:t('order.modelYear'),
                dataIndex: 'model_year',
                key:'model_year',
                align:'left',
                ellipsis:true,
                tooltip:true,
            },
            {
                // title: '车型',
                title:t('order.modelVersion'),
                dataIndex: 'model_version',
                key:'model_version',
                align:'left',
                width:280,
                ellipsis:true,
                tooltip:true,
            },
            {
                // title: '颜色',
                title:t('order.color'),
                dataIndex: 'frame_color',
                key:'frame_color',
                align:'left',
                width:140,
                ellipsis:true,
                tooltip:true,
            },
            {
                // title: '车架类型',
                title:t('order.frame'),
                dataIndex: 'frame_shape',
                key:'frame_shape',
                align:'left',
                ellipsis:true,
                tooltip:true,
            },
            {
                // title: '尺寸',
                title:t('order.size'),
                dataIndex: 'frame_size',
                key:'frame_size',
                align:'left',
                width:120,
                ellipsis:true,
                tooltip:true,
            },
            {
                // title: '数量',
                title:t('order.orderQty'),
                dataIndex: 'quantity',
                key:'quantity',
                align:'left',
                ellipsis:true,
                tooltip:true,
            },
            {
                // title: 'EK售格',
                title:t('order.ek'),
                dataIndex: 'ek_price',
                key:'ek_price',
                align:'left',
                ellipsis:true,
                tooltip:true,
        }],
        configs:{
            scroll:{
                // x:1000,
                y:560,
            },
            stripe:false,
            bordered:false,
            size:'large',
            loading:false,
            summary:({columns, data}) => { // 合计行
                let countData = { quantity: 0, ek_price: 0}
                data.forEach(record => {
                    countData.quantity += record.quantity
                    countData.ek_price += multiply(record.quantity,Number(record.ek_price.replaceAll('.','').replaceAll(',','.')))
                })

                return [{
                    No:t('order.total'),
                    quantity: countData.quantity,
                    ek_price: toThousands(countData.ek_price,'de-DE'),
                }]
            },
            expandable:'',
            expand:false,
            pagination:false,
        }
    })

    const sum_bike = ref(0)
    const sum_price = ref(0)
    const subTotal = ref(0)
    const orderNowDisabled = ref(true)
    const dealer = ref('')
    const user_note = ref('')


    const clickTab = (val)=>{
        tabActive.value = val
        if(val == 1){
            setFilterOpt(trekking7_table.value,'model_year')
            setFilterOpt(trekking7_table.value,'model_version')
            setFilterOpt(trekking7_table.value,'frame_shape')
            setFilterOpt(trekking7_table.value,'frame_color')
        }else if(val == 2){
            setFilterOpt(mountain7_table.value,'model_year')
            setFilterOpt(mountain7_table.value,'model_version')
            setFilterOpt(mountain7_table.value,'frame_shape')
            setFilterOpt(mountain7_table.value,'frame_color')
        }else if(val == 3){
            setFilterOpt(mountain7Carbon_table.value,'model_year')
            setFilterOpt(mountain7Carbon_table.value,'model_version')
            setFilterOpt(mountain7Carbon_table.value,'frame_shape')
            setFilterOpt(mountain7Carbon_table.value,'frame_color')
        }else if(val == 4){
            setFilterOpt(trekking7MY23_table.value,'model_year')
            setFilterOpt(trekking7MY23_table.value,'model_version')
            setFilterOpt(trekking7MY23_table.value,'frame_shape')
            setFilterOpt(trekking7MY23_table.value,'frame_color')
        }
    }

    // 表格筛选条件重置
    const resetTableFilter = ()=>{
        tableRef.value.resetFilter()
    }

    const fun = (tableData)=>{
        tableData.forEach(i=>{
            if(!!i.quantity){
                sum_bike.value = sum_bike.value + i.quantity
                if(sum_price.value.toString().indexOf('.') > -1){
                    sum_price.value = Number(sum_price.value.replaceAll('.','').replaceAll(',','.')) + multiply(Number(i.ek_price.replaceAll('.','').replaceAll(',','.')), i.quantity)
                }else{
                    sum_price.value = Number(sum_price.value) + multiply(Number(i.ek_price.replaceAll('.','').replaceAll(',','.')), i.quantity)
                }
                sum_price.value = toThousands(sum_price.value,'de-DE')
            } 
        })
    }

    // 统计下单车辆总数、总价
    const countBike = ()=>{
        sum_bike.value = 0
        sum_price.value = 0

        fun(trekking7_table.value.datas)
        fun(mountain7_table.value.datas)
        fun(mountain7Carbon_table.value.datas)
        fun(trekking7MY23_table.value.datas)
    }

    const handleInputTK7 = (val, row)=>{
        console.log(val,'vvvv',val !== '', val === undefined, row)
        if(row.inventory_val == 0){
            Modal.info({
                title: t('order.currentlyNotAvailable2'),
                titleAlign:'start',
                width:500,
                hideCancel:true,
                maskClosable:false,
                bodyStyle:{fontSize:'19px'},
                content: t('order.currentlyNotAvailableTip'),
                okText:t('base.ok'),
            })
        }else if(row.inventory_val > 0 && row.inventory_val < 10 && val > 10){
            Modal.info({
                title: t('base.tip'),
                titleAlign:'start',
                width:500,
                hideCancel:true,
                maskClosable:false,
                bodyStyle:{fontSize:'19px'},
                content: t('order.exceedAvailableStock'),
                okText:t('base.ok'),
                onOk:()=>{
                    row.quantity = ''
                }
            })
        }
    }

    const handleInputMT7 = (val, row)=>{
        if(row.inventory_val == 0){
            Modal.info({
                title: t('order.currentlyNotAvailable2'),
                titleAlign:'start',
                width:500,
                hideCancel:true,
                maskClosable:false,
                bodyStyle:{fontSize:'19px'},
                content: t('order.currentlyNotAvailableTip'),
                okText:t('base.ok'),
            })
        }else if(row.inventory_val > 0 && row.inventory_val < 10 && val > 10){
            Modal.info({
                title: t('base.tip'),
                titleAlign:'start',
                width:500,
                hideCancel:true,
                maskClosable:false,
                bodyStyle:{fontSize:'19px'},
                content: t('order.exceedAvailableStock'),
                okText:t('base.ok'),
                onOk:()=>{
                    console.log(row,'rrrr')
                    row.quantity = ''
                    console.log(row,'rrrr2322222')
                }
            })
        }
    }

    const handleInputMT7C = (val, row)=>{
        if(row.inventory_val == 0){
            Modal.info({
                title: t('order.currentlyNotAvailable2'),
                titleAlign:'start',
                width:500,
                hideCancel:true,
                maskClosable:false,
                bodyStyle:{fontSize:'19px'},
                content: t('order.currentlyNotAvailableTip'),
                okText:t('base.ok'),
            })
        }else if(row.inventory_val > 0 && row.inventory_val < 10 && val > 10){
            Modal.info({
                title: t('base.tip'),
                titleAlign:'start',
                width:500,
                hideCancel:true,
                maskClosable:false,
                bodyStyle:{fontSize:'19px'},
                content: t('order.exceedAvailableStock'),
                okText:t('base.ok'),
                onOk:()=>{
                    row.quantity = ''
                }
            })
        }
    }

    const handleInputTK723 = (val, row)=>{
        if(row.inventory_val == 0){
            Modal.info({
                title: t('order.currentlyNotAvailable2'),
                titleAlign:'start',
                width:500,
                hideCancel:true,
                maskClosable:false,
                bodyStyle:{fontSize:'19px'},
                content: t('order.currentlyNotAvailableTip'),
                okText:t('base.ok'),
            })
        }else if(row.inventory_val > 0 && row.inventory_val < 10 && val > 10){
            Modal.info({
                title: t('base.tip'),
                titleAlign:'start',
                width:500,
                hideCancel:true,
                maskClosable:false,
                bodyStyle:{fontSize:'19px'},
                content: t('order.exceedAvailableStock'),
                okText:t('base.ok'),
                onOk:()=>{
                    row.quantity = ''
                }
            })
        }
    }

    // 监听数量变化
    const handleChangeTK7 = (value, row)=>{
        // console.log(value, row,'rowwww')
        countBike()
    }

    const handleChangeMT7 = (value, row)=>{
        countBike()
    }

    const handleChangeMT7C = (value, row)=>{
        countBike()
    }

    const handleChangeTK723 = (value, row)=>{
        countBike()
    }

    // 
    const changeSelect = (value,row)=>{
        console.log(value,row,'ggghhhh')
    }

    // 确认选择经销商
    // const handleSelectDealerBeforeOk = async ()=>{
    //     console.log(dealer_source_id.value,'dealer_source_id.value')
    //     dealerInfo.value.source = dealer_source_id.value
    //     localeStore.changeDealerSource(dealer_source_id.value)
    //     localeStore.changeShowSelectDealer(false)
    //     localeStore.changeDealerSourceId(dealer_source_id.value)
    //     await getDealer()
    //     getOrderList()
    // }

    // const cancelSelect = ()=>{
    //     localeStore.changeShowSelectDealer(false)
    //     dealer_source_id.value = localeStore.dealerSourceId
    // }

    // 金额千分位格式化
    const toThousands = (val,type)=> { // type: 'de-DE'--德国计数法  'zh-ZH'--中国计数法  'fr-FR'--法国计数法
        return new Intl.NumberFormat(type).format(val)
    }

    // 获取列表
    const getOrderList = async ()=>{
        let param = {
            customer_code:dealer.value.customer_number
        }
        trekking7_table.value.configs.loading = true

        let res = await OrderApi.getInstance().getOrderList(param)
        if(res.code == 0){
            trekking7_table.value.configs.loading = false
            let arr = ['Trekking 7 MY24', 'Mountain 7 MY24', 'Mountain 7 Carbon MY24', 'Trekking 7 MY23']

            arr.forEach(i=>{
                res.data[i].length > 0 && res.data[i].forEach((i,index)=>{
                    i.No = index + 1
                    i.model_version = `${i.model}, ${i.hf_version}`
                    i.model_year = i.model_year == undefined? '-' : i.model_year == 'MY23'? '2023' : '2024'
                    i.standard_price = i.standard_price == undefined? '-' : toThousands(i.standard_price,'de-DE')
                    i.ek_price = i.ek_price == undefined? '-' : toThousands(i.ek_price,'de-DE')
                    i.inventory_val = i.ek_price == '-' || i.inventory_item_status_code !== 'Active'? null : i.availability_quantity_total == undefined? 0 : i.availability_quantity_total
                    i.inventory_text = i.ek_price == '-' || i.inventory_item_status_code !== 'Active'? t('order.unavailable') : i.availability_quantity_total > 10? t('order.immediately') : i.availability_quantity_total <= 10 && i.availability_quantity_total >= 1? '<10' : t('order.currentlyNotAvailable')
                    i.quantity = ''
                    i.delivery_type = '1'
                })
            })

            trekking7_table.value.datas = []
            mountain7_table.value.datas = []
            mountain7Carbon_table.value.datas = []
            trekking7MY23_table.value.datas = []

            res.data['Trekking 7 MY24'].forEach(i=>{
                if(i.sku.indexOf('+') < 0){
                    trekking7_table.value.datas.push(i)
                }
            })
            res.data['Mountain 7 MY24'].forEach(i=>{
                if(i.sku.indexOf('+') < 0){
                    mountain7_table.value.datas.push(i)
                }
            })
            res.data['Mountain 7 Carbon MY24'].forEach(i=>{
                if(i.sku.indexOf('+') < 0){
                    mountain7Carbon_table.value.datas.push(i)
                }
            })
            res.data['Trekking 7 MY23'].forEach(i=>{
                if(i.sku.indexOf('+') < 0){
                    trekking7MY23_table.value.datas.push(i)
                }
            })
            // trekking7_table.value.datas = res.data['Trekking 7 MY24']
            // mountain7_table.value.datas = res.data['Mountain 7 MY24']
            // mountain7Carbon_table.value.datas = res.data['Mountain 7 Carbon MY24']
            // trekking7MY23_table.value.datas = res.data['Trekking 7 MY23']

            setFilterOpt(trekking7_table.value,'model_year')
            setFilterOpt(trekking7_table.value,'model_version')
            setFilterOpt(trekking7_table.value,'frame_shape')
            setFilterOpt(trekking7_table.value,'frame_color')
            
            
        }else{
            Message.error(res.msg)
        }
    }

    // 过滤筛选项
    const setFilterOpt = (table, table_key)=>{
        let arr = []
        let index = table.columns.findIndex(i=>i.key == table_key) 
        table.columns[index].filterable.filters = []

        table.datas.forEach(i=>{
            if(!arr.includes(i[table_key])){
                arr.push(i[table_key])
            }
        })
        arr.forEach(i=>{
            table.columns[index].filterable.filters.push({
                text: i,
                value:i,
            })
        })
    }

    // 下单
    const orderNow = ()=>{
        for(let i=0; i<trekking7_table.value.datas.length; i++){
            let item = trekking7_table.value.datas[i]
            if(!!item.quantity && item.delivery_type == ''){
                Message.error(`【${t('order.Trekking7')}】${t('order.deliveryTypeEmptyTip')}`)
                return
            }
        }

        for(let i=0; i<mountain7_table.value.datas.length; i++){
            let item = mountain7_table.value.datas[i]
            if(!!item.quantity && item.delivery_type == ''){
                Message.error(`【${t('order.Mountain7')}】${t('order.deliveryTypeEmptyTip')}`)
                return
            }
        }

        for(let i=0; i<mountain7Carbon_table.value.datas.length; i++){
            let item = mountain7Carbon_table.value.datas[i]
            if(!!item.quantity && item.delivery_type == ''){
                Message.error(`【${t('order.Mountain7Carbon')}】${t('order.deliveryTypeEmptyTip')}`)
                return
            }
        }  
        
        for(let i=0; i<trekking7MY23_table.value.datas.length; i++){
            let item = trekking7MY23_table.value.datas[i]
            if(!!item.quantity && item.delivery_type == ''){
                Message.error(`【${t('order.Trekking7MY23')}】${t('order.deliveryTypeEmptyTip')}`)
                return
            }
        }  

        summary_table.value.datas = []
        trekking7_table.value.datas.forEach(i=>{
            if(!!i.quantity){
                i.quantity = Number(i.quantity)
                i.ek_price = i.ek_price
                summary_table.value.datas.push(i)
            }
        })
        mountain7_table.value.datas.forEach(i=>{
            if(!!i.quantity){
                i.quantity = Number(i.quantity)
                i.ek_price = i.ek_price
                summary_table.value.datas.push(i)
            }
        })
        mountain7Carbon_table.value.datas.forEach(i=>{
            if(!!i.quantity){
                i.quantity = Number(i.quantity)
                i.ek_price = i.ek_price
                summary_table.value.datas.push(i)
            }
        })
        trekking7MY23_table.value.datas.forEach(i=>{
            if(!!i.quantity){
                i.quantity = Number(i.quantity)
                i.ek_price = i.ek_price
                summary_table.value.datas.push(i)
            }
        })

        subTotal.value = 0
        summary_table.value.datas.forEach((i,index)=>{
            i.No = index + 1
            subTotal.value += multiply(i.quantity, Number(i.ek_price.replaceAll('.','').replaceAll(',','.')))
        })
        subTotal.value = toThousands(subTotal.value,'de-DE')
        
        visible.value = summary_table.value.datas.length > 0
    }

    // 确认下单
    const handleBeforeOk = async()=>{
        let param = {
            source:dealerInfo.value.source,
            order_type:0, // 0:销售
            order_channel_type:1,
            business_order_status:1, // 1:下单
            customer_type:1, // 1:经销商
            order_amount:sum_price.value.replaceAll('.','').replaceAll(',','.'), // 订单总额
            quantity:sum_bike.value, // 整车总数
            bike_quantity:sum_bike.value, // 整车数量
            pay_amount:sum_price.value.replaceAll('.','').replaceAll(',','.'), // 最终下单金额
            country: dealer.value.country,
            user_note:user_note.value,// 备注
            order_line_vos:[],
        }

        summary_table.value.datas.forEach(i=>{
            param.order_line_vos.push({
                sku:i.sku,
                inventory_code:i.inventory_code,
                product_code:i.category_code,
                delivery_type:i.delivery_type,
                amount:Number(i.ek_price.replaceAll('.','').replaceAll(',','.')),//单价
                quantity: i.quantity,
                pay_amount: multiply(i.quantity, Number(i.ek_price.replaceAll('.','').replaceAll(',','.'))),//行总价
            })
        })

        console.log(param,'param')
        let res = await OrderApi.getInstance().saveOrder(param)
        if(res.code == 0){
            sum_bike.value = 0
            sum_price.value = 0
            subTotal.value = 0
            orderNowDisabled.value = true 
            user_note.value = ''
            // tabActive.value = 1
            clickTab(1)

            trekking7_table.value.datas.forEach(i=>{
                i.quantity = ''
                i.delivery_type = '1'
            })
            mountain7_table.value.datas.forEach(i=>{
                i.quantity = ''
                i.delivery_type = '1'
            })
            mountain7Carbon_table.value.datas.forEach(i=>{
                i.quantity = ''
                i.delivery_type = '1'
            })
            trekking7MY23_table.value.datas.forEach(i=>{
                i.quantity = ''
                i.delivery_type = '1'
            })

            Modal.info({
                title: t('order.orderSentSuccessful2'),
                titleAlign:'start',
                width:500,
                hideCancel:true,
                maskClosable:false,
                bodyStyle:{fontSize:'22px'},
                content: t('order.orderSentSuccessful3'),
                okText:t('base.ok'),
            })
        }else{
            Message.error(res.msg)
        }
    }

    // 获取经销商
    const getDealer = async ()=>{
        let res = await OrderApi.getInstance().getDealer({id:dealerInfo.value.source})
        if(res.code == 0){
            dealer.value = res.data[0]

            if(dealer.value.active_flag == 0){
                Modal.warning({
                    title: t('base.tip'),
                    titleAlign:'start',
                    width:600,
                    content: ()=>h('div',{style:{fontSize:'16px'}},t('order.dealerInactiveTip')),
                    hideCancel:true,
                    maskClosable:false,
                    okText:t('base.ok'),
                    onOk:()=>{
                        router.replace('/Login')
                        sessionStorage.removeItem('token')
                    },
                })
            }
        }else{
            Message.error(res.msg)
        }
    }

    // 获取经销商下拉数据
    // const getDealerOption = async ()=>{
    //     let res = await OrderApi.getInstance().getSaleDealerList({})
    //     if(res.code == 0){
    //         dealerOption.value = []
    //         res.data.length > 0 && res.data.forEach(i=>{
    //             dealerOption.value.push({
    //                 label:i.customer_name,
    //                 value:i.source_id,
    //             })
    //         })
    //     }else{
    //         Message.error(res.msg)
    //     }
    // }

    watch(()=>sum_bike.value,(val)=>{
        orderNowDisabled.value = val === 0
    })

    // watch(()=>localeStore.showSelectDealer,(val)=>{
    //     if(dealerInfo.value.role && dealerInfo.value.role.join(',').indexOf('13') > -1){
    //         selectDealerVisible.value = val
    //     }
    // })

    // watch(()=>localeStore.saleDealerOption,(val)=>{
    //     if(dealerInfo.value.role && dealerInfo.value.role.join(',').indexOf('13') > -1){
    //         dealerOption.value = val
    //         dealer_source_id.value = dealerOption.value[0].value
    //     }
    // })

    onMounted(async ()=>{
        if(sessionStorage.getItem('dealerInfo')){
            dealerInfo.value = JSON.parse(sessionStorage.getItem('dealerInfo'))
        }

        await getDealer()
        getOrderList()
        

        // 登录人是销售人员
        // if(dealerInfo.value.role.join(',').indexOf('13') > -1){
        //     selectDealerVisible.value = true
        // }else{
        //     await getDealer()
        //     getOrderList()
        // }
    })
</script>

<style lang="less" scoped>
.content_hd{
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 1000;
    padding-top: 10px;
    width: 100%;
    background: #f5f7f8;
}
.dealer_info{
    padding: 4px 40px;
    margin-bottom: 10px;
    margin-left: 40px;
    background: #fff;
    border-radius: 10px;
    font-size: 12px;
    color: #3C4257;

    .label{
        display: inline-block;
        width: 140px;
    }

    .value{
        font-weight: 700;
    }
}

.tab{
    margin-bottom: 10px;
    margin-left: 40px;
    .tabItem{
        display: inline-block;
        padding:6px 20px;
        margin-right: 10px;
        color: #777;
        background: #fff;
        border-radius: 6px;
        cursor: pointer;
    }

    .tabItem:hover{
        background: #eee;
    }

    .tabItemActive,.tabItemActive:hover{
        background: #000;
        color: #fff;
    }


    .resetTableFilter{
        float: right;
        margin-right:40px;
        margin-top: 1px;
        background: #000;
        color: #fff;
    }   
}

.ordreList{
    margin-top: 123px;
    padding-bottom: 115px;
}

/deep/ .arco-table-th{
    background: #f1f3f9;
    border-bottom: 1px solid #eee;
    padding: 3px 0;
    font-weight: 600;
    font-size: 14px;
}

// /deep/ .arco-table-filters{
//     right: 15px;
// }

/deep/ .arco-table-td{
    padding: 2px 0;
    // border-bottom: none;
}

/deep/ .arco-table-container{
    padding-bottom:0;
}

/deep/ .arco-table .arco-table-cell {
    padding: 3px 16px;
}

/deep/ .arco-table tfoot .arco-table-td{
    background: #fff;
    border-bottom: 1px solid #e3e3e3;
    font-weight: 600;
}

/deep/ .table_pagination{
    display: none;
}

.summary{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    // z-index: 1000;
    padding: 10px 80px;
    width:100%;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);

    .sum_block{
        display: flex;
        width: 300px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        .value{
            font-size: 36px;
            font-weight: 600;
        }

        .text{
            font-size: 14px;
            font-weight: 400;
            font-family: "DM Mono";
        }

        .text3{
            margin-top: 5px;
        }
    }

    .block3{
        margin-right: 150px;
    }

    .orderNow{
        margin-top: 10px;
        width: 130px;
        height: 40px;
        background: #000;
        border-radius: 50px;
    }

    .orderNowDisabeld{
        background: #999;
    }
}

/deep/ .arco-modal-header{
    border-bottom: none;

    .arco-modal-title{
        font-weight: 600;
    }
}

/deep/ .arco-modal-body{
    padding: 0px 20px 20px;
}

/deep/ .arco-modal-footer{
    border-top: none;

    .arco-btn {
        background: #000;
        color: #fff;
        border-radius: 50px;
    }

    .arco-btn-secondary{
        background: #b3b3b3;
    }
}

/deep/ .arco-modal-title{
    font-size: 19px !important;
}

.sum_dialog{
    .sum_dialog_center{
        display: flex;
        margin: 30px 0;

        .addNote{
            display: flex;
            flex:1;
            flex-direction: column;
            margin-right: 50px;
            // justify-content: flex-end;

            .title{
                margin-bottom: 10px;
                font-weight: 600;
            }

            /deep/ .arco-textarea{
                padding: 4px 30px 4px 12px;
            }
        }

        .subTotal{
            display: flex;
            flex:1;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;

            .sum{
                margin-bottom: 10px;
                font-weight: 600;
                font-size: 20px;
            }

            .link{
                color: #1226AA;
                font-weight: 600;
            }

            .link:hover{
                text-decoration: underline;
            }
        }
    }
}


.searchFilter{
    padding: 20px;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.12), 0px 15px 35px 0px rgba(60, 66, 87, 0.08);
}
</style>