/*
 * @Author: caizexin caizexin@kjgb.net
 * @Date: 2023-07-10 11:32:28
 */
export default{
    base:{
        headerText:'Online-Bestellsystem',
        noEmpty:'darf nicht leer sein!',
        noPage:'Seite existiert nicht',
        sorry:'Entschuldigung',
        login:'Anmeldung',
        tip:'Tipps',
        operateSuccess:'Operation successful!',
        cancel:'Abbrechen',
        ok:'OK',
        reset:'Zurücksetzen',
        operate:'Bedienen',
        more:'Mehr',
        save:'speichern',
        loading:'Laden...',
        yes:'JA',
        no:'NEIN',
        select:'Wählen',
        formatError:'format error',
        logout:'Abmelden',
        saveSuccess:'Erfolgreich gespeichert',
        sendSuccess:'Erfolgreich gesendet',
        sending:'Senden...',
        enterSomething:'Bitte geben Sie etwas ein...',
    },
    login:{
        title:"Willkommen beim Hepha Online-Bestellsystem",
        login:'Anmelden',
        logout:'Abmelden',
        logining:'Anmeldung läuft...',
        password:'Passwort',
        forgetPsd:'Passwort vergessen?',
        becomeDealer:'Händler werden',
        forgetTitle:'Passwort zurücksetzen',
        forgetText:'Bitte geben Sie die E-Mail-Adresse ein, die Sie bei der Registrierung verwendet haben, um Ihr Passwort zurückzusetzen.',
        forgetText2:'Wir haben einen Verifizierungscode an Ihre E-Mail gesendet. Wenn Sie ihn nicht erhalten haben, bitte gedulden Sie sich und stellen Sie sicher, dass Sie die richtige E-Mail-Adresse angegeben haben.',
        sent:'Gesendet',
        email:'E-Mail-Adresse',
        email2:"E-Mail",
        emailEmpty:'E-Mail darf nicht leer sein!',
        passwordEmpty:'Passwort darf nicht leer sein!',
        emailMsgEmpty:'E-Mail-Adresse ist nicht leer!',
        code:'Verifizierungscode',
        codeMsgEmpty:'Verifizierungscode ist nicht leer!',
        newPasswordEmpty:'Neues Passwort ist nicht leer!',
        confirmPasswordEmpty:'Passwortbestätigung ist nicht leer!',
        editPwd:'Passwortänderung erfolgreich',
        vaildPwd:'Passwortinkonsistenz!',
        reset:'Zurücksetzen',
        newPassword:'Neues Passwort',
        confirmPassword:'Passwort bestätigen',
        sentCodeTip:'Sie können einen Verifizierungscode nur einmal pro Minute anfordern.',
        sentCodeTip2:'Es wird dauern ',
        sentCodeTip3:" um den Verifizierungscode erneut zu senden",

        error_email:'【Firmenadresse】E-Mail Format Fehler!',
        store_error_email:'【Adresse speichern】E-Mail Format Fehler!',
        delivery_error_email:'【Lieferadresse】E-Mail Format Fehler!',
        passwordVaildMsg:'Passwortlänge 6-10 Ziffern, bestehend aus Zahlen und Buchstaben',
        logoutTip:'Sind Sie sicher, dass Sie sich abmelden möchten?',
        logoutTitle:'Abmeldetipp',

        loginSuccessful:'Login erfolgreich!',
        resetSuccessful:'Passwort zurücksetzen erfolgreich!',

        completeVeriftyTitle:'Bitte vervollständigen Sie die Sicherheitsüberprüfung',
        swipeRight:'Wischen Sie nach rechts, um die Überprüfung abzuschließen',
        verificationFailed:"Überprüfung fehlgeschlagen",
        verificationSuccessful:'Überprüfung erfolgreich',
        clickMoreOne:'Bitte klicken Sie nacheinander darauf',
    },
    menu:{
        orderList:'Bestellliste', 
    },
    becomeDealer:{
        title:'WERDEN SIE DEALER',
        text:'Wir freuen uns sehr, Sie als HEPHA Händler begrüßen zu dürfen! Bitte geben Sie uns einige Informationen zu Ihrem Unternehmen, damit wir Sie richtig einrichten können!',
        companyAddress:'Firmenadresse',
        storeAddress:'Adresse speichern',
        deliveryAddress:'Lieferadresse',
        companyName:'Firmenname',
        contactPerson:'Ansprechpartner',
        land:'Land',
        country:'Land',
        street:'Straße',
        houseNumber:'Hausnummer',
        plz:'PLZ',
        ort:'Ort',
        telephone:'Telefon',
        mobile:'Mobil',
        email:'E-Mail',
        website:'Website',
        city:'Stadt',
        zipCode:'Postleitzahl',
        vatNo:'MwSt.-Nr.',
        sameCompanyAddress:'Gleiche wie Firmenadresse',
        sameStoreAddress:'Gleiche wie die Adresse des Ladens',
        severalAddresses:'Mehrere Adressen, daher später Eintrag.',
        branches:'Zweige?',
        branches2:'Zweige',
        no:'Nein',
        yes:'Ja',
        yesHave:'Ja, wir haben',
        salesAreaApprox:'Verkaufsfläche ca.',
        serviceWorkshop:'Servicewerkstatt?',
        foundation:'Servicewerkstatt?',
        top5Brands:'Ihre Top 5 Marken',
        checkText:'Hiermit bestätige ich die Datenschutzbestimmungen der Hepha GmbH und ermächtige HEPHA GmbH ausdrücklich, mich per E-Mail oder Telefon zu kontaktieren.',
        sendNow:'JETZT SENDEN',
        shopName:'Name des shops',
        bico:'BICO',

        storeStreetEmpty:'Die Straße der Filialadresse darf nicht leer sein!',
        storeHouseNumberEmpty:'Die Hausnummer der Filialadresse darf nicht leer sein!',
        storeOrtEmpty:'Die Stadt der Adresse des Ladens darf nicht leer sein!',
        storePlzEmpty:'Die Postleitzahl der Filialadresse darf nicht leer sein!',
        storePhoneEmpty:'Das Telefon der Filialadresse darf nicht leer sein!',
        storeEmailEmpty:'Die E-Mail-Adresse des Shops darf nicht leer sein!',

        deliveryStreetEmpty:'Die Straße der Lieferadresse darf nicht leer sein!',
        deliveryHouseNumberEmpty:'Die Hausnummer der Lieferadresse darf nicht leer sein!',
        deliveryOrtEmpty:'Der Ort der Lieferadresse darf nicht leer sein!',
        deliveryPlzEmpty:'Die Postleitzahl der Lieferadresse darf nicht leer sein!',
        deliveryPhoneEmpty:'Das Telefon der Lieferadresse darf nicht leer sein!',
        deliveryEmailEmpty:'Die E-Mail der Lieferadresse darf nicht leer sein!',
        deliveryBranchEmpty:'Die Filialen der Lieferadresse dürfen nicht leer sein!',
        deliveryWorkshopEmpty:'Die Servicewerkstatt der Lieferadresse darf nicht leer sein!',
        submitSuccessful:'Erfolgreich einreichen!',
        waitAuditTip:'Vielen Dank für Ihre Anmeldung, wir melden uns in Kürze bei Ihnen.'
    },
    order:{
        customer:'Kunde',
        level:'Ebene',

        Trekking7:'Trekking 7',
        Mountain7:'Mountain 7',
        Mountain7Carbon:'Mountain 7 Carbon',
        Trekking7MY23:'Trekking 7 MY23',

        projectNumber:'SKU',
        modelYear:'Modell-jahr',
        modelVersion:'Model & Version',
        color:'Farbe',
        frame:'Rahmen',
        size:'Größe',
        uvp:'UVP(€)',
        ek:'EK(€)',
        availability:'Liefer-barkeit',
        orderQty:'Order-Menge',
        deliveryType:'Liefer-art',

        immediately:'Sofort',
        unavailable:'Nicht verfügbar',
        currentlyNotAvailable:'Derzeit nicht verfügbar',

        currentlyNotAvailable2:'Derzeit Nicht Verfügbar',
        currentlyNotAvailableTip:'Momentan haben wir keinen Lagerbestand zur Verfügung. Wir werden aber in Zukunft liefern können.',

        numberOfBikes:'Anzahl Bikes',
        total:'Gesamt',
        orderNow:'Jetzt Bestellen',
        allPrices:'Alle Preise netto zzgl. Versand',
        
        summaryOrderTitle:'Alle Preise netto zzgl. Versand',
        total:'Insgesamt',
        addNote:'Kommentar hinzufügen:',
        subTotal:'Teilsumme',
        sendOrder:'Bestellung Senden',
        cancel:'Ändern',
        accept:'Mit Bestellung senden akzeptieren Sie unsere',
        and:'und',
        term:'AGBs',
        policy:'Datenschutzbestimmungen',
        orderSentSuccessful:'Auftrag erfolgreich gesendet',
        orderSentSuccessful2:'Your order has been submitted. ',
        orderSentSuccessful3:'You will receive an order confirmation by e-mailand later an order confirmation.',

        standard:'Standard',
        express:'Express',
        collection:'Abholung',

        black:'Schwarz',
        grey:'Grau',
        lightGrey:'Hellgrau',
        darkGrey:'Dunkelgrau',
        blueGrey:'Blaugrau',
        warmGrey:'Warmgrau',
        moos:'Moos',
        sand:'Sand',
        mint:'Minze',
        olive:'Olive',
        anthracite:'Anthrazit',

        highStep:'Hohe Stufe',
        lowStep:'Niedrige Stufe',
        stepThrough:'Schritt durch',
        hardtail:'Hardtail',
        fullSuspension:'Vollfederung',

        deliveryTypeEmptyTip:'Lieferart darf nicht leer sein!',
        resetFilter:'Alle Filter löschen',
        exceedAvailableStock:'Verfügbare Bestände überschreiten',

        selectDealer:'Händler auswählen',
        dealerInactiveTip:'Bitte kontaktieren Sie unser Vertriebsteam unter+49 (0) 8142 284481 oder sales@hepha.com',
    }
    
}