/*
 * @Author: caizexin caizexin@kjgb.net
 * @Date: 2023-03-07 10:11:17
 */
import { createI18n } from 'vue-i18n';
import zh from '@/locale/zh.js';
import en from '@/locale/en.js';
import de from '@/locale/de.js';

const messages = {
    zh,en,de
}

const locale = localStorage.getItem('dealerLocale')? localStorage.getItem('dealerLocale') : 'en'
// console.log(locale,'index')
const i18n = createI18n({
    legacy:false, // 使用 Composition API 模式，则需要将其设置为false
    globalInjection:true, // // 全局注入 $t 函数
    locale:locale,
    messages:messages
})

export default i18n