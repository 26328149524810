<template>
    <div class="contanier">
        <Header></Header>

        <div class="loginForm" v-if="showForm2">
            <div class="form_header">
                <img class="back" src="@/assets/image/arrow_left.svg" alt="" width="30" @click="back('login')">
                <div class="text">{{ $t('login.forgetTitle') }}</div>
                <div class="subText">{{ $t('login.forgetText') }}</div>
            </div>
            <div>
                <a-form
                    :model="code_email"
                    layout="vertical"
                    :style="{marginTop: '10px' }"
                    :size="size"
                >
                    <a-form-item
                        :help="emailMsg"
                        :label="$t('login.email')"
                        :required="true"
                        :validate-status="email_status"
                        feedback
                    >
                        <a-input
                            v-model="code_email"
                            allow-clear
                        />
                    </a-form-item>

                    <a-button type="primary" class="sendBtn" :class="{'sendTbnDisabeld':sentDisabled}" :disabled="sentDisabled" @click="beforeSent">{{ $t('login.sent') }}</a-button>
                    <div class="sentCodeTip">{{ sentCodeTip }}</div>
                </a-form>
            </div>
        </div>

        <div class="loginForm" v-else>
            <div class="form_header">
                <img class="back" src="@/assets/image/arrow_left.svg" alt="" width="36"  @click="back('code')">
                <div class="text">{{ $t('login.forgetTitle') }}</div>
                <div class="subText">{{ $t('login.forgetText2') }}</div>
            </div>
            <div>
                <a-form
                    :model="form"
                    layout="vertical"
                    :style="{marginTop: '10px' }"
                    :size="size"
                >
                    <a-form-item
                        :label="$t('login.code')"
                        :required="true"
                        :help="codeMsg"
                        :validate-status="code_status"
                        feedback
                    >
                        <a-input
                            v-model="form.code"
                            allow-clear
                        />
                    </a-form-item>
                    <a-form-item
                        :label="$t('login.newPassword')"
                        :required="true"
                        :help="newPdwMsg"
                        :validate-status="new_password_status"
                        feedback
                    >
                        <a-input-password
                            v-model="form.new_password"
                            allow-clear
                        />
                    </a-form-item>
                    <a-form-item
                        :label="$t('login.confirmPassword')"
                        :required="true"
                        :help="confirmPdwMsg"
                        :validate-status="confirm_password_status"
                        feedback
                    >
                        <a-input-password
                            v-model="form.password"
                            allow-clear
                            @blur="blur"
                        />
                    </a-form-item>

                    <a-button type="primary" class="resetBtn" @click="reset">{{ $t('login.reset') }}</a-button>
                </a-form>
            </div>
        </div>

        <!-- 滑块验证弹窗 -->
        <Verify
            class="verify"
            @success="success" 
            mode="pop" 
            captchaType="blockPuzzle"
            :imgSize="{ width: '408px', height: '155px' }"
            ref="verifyRef"
        ></Verify>
    </div>
</template>

<script setup>
    import { onMounted, reactive, ref, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { LoginApi } from '@/api/LoginApi.js';
    import { Message } from '@arco-design/web-vue';
    import { useI18n } from 'vue-i18n';
    import Header from './component/Header.vue';
    import Verify from "@/components/verifition/Verify";

    const router = useRouter()
    const { t } = useI18n()

    const language = localStorage.getItem('dealerLocale')? ref(localStorage.getItem('dealerLocale')) : ref('en')
    const emailMsg = ref('')
    const codeMsg = ref('')
    const newPdwMsg = ref('')
    const confirmPdwMsg = ref('')
    const email_status = ref('')
    const code_status = ref('')
    const new_password_status = ref('')
    const confirm_password_status = ref('')
    const showForm2 = ref(true)
    const sentDisabled = ref(false)
    const sentCodeTip = ref('')
    const time = ref(60)
    const code_email = ref('')
    const verifyRef = ref(null)
    const captcha_verification = ref('')
    const form = reactive({
        code:'',
        new_password:'',
        password:''
    })

    const vaild = ()=>{
        if(!form.new_password && !form.password && !form.code){
            codeMsg.value = t('login.codeMsgEmpty')
            code_status.value = 'error'
            newPdwMsg.value = t('login.newPasswordEmpty')
            new_password_status.value = 'error'
            confirmPdwMsg.value = t('login.confirmPasswordEmpty')
            confirm_password_status.value = 'error'

            return false
        }else if(!form.code){
            codeMsg.value = t('login.codeMsgEmpty')
            code_status.value = 'error'
            return false
        }else if(!form.new_password){
            newPdwMsg.value = t('login.newPasswordEmpty')
            new_password_status.value = 'error'
            return false
        }else if(!form.password){
            confirmPdwMsg.value = t('login.confirmPasswordEmpty')
            confirm_password_status.value = 'error'
            return false
        }else if(form.password !== form.new_password){
            confirmPdwMsg.value = t('login.vaildPwd')
            confirm_password_status.value = 'error'
            return false
        }

        return true
    }

    const back = (type)=>{
        if(type == 'code'){
            showForm2.value = true
            form.code = ''
            form.new_password = ''
            form.password = ''
            codeMsg.value = ''
            code_status.value = ''
            newPdwMsg.value = ''
            new_password_status.value = ''
            confirmPdwMsg.value = ''
            confirm_password_status.value = ''
        } 
        else router.push('/Login')
    }

    const blur = ()=>{
        if(form.password !== form.new_password && form.password){
            confirmPdwMsg.value = t('login.vaildPwd')
            confirm_password_status.value = 'error'
        }else{
            confirmPdwMsg.value = ''
            confirm_password_status.value = ''
        }
    }

    const changeSelect = (val)=>{
        localStorage.setItem('dealerLocale',val)
        location.reload()
    }

    const beforeSent = ()=>{
        if(!code_email.value){
            emailMsg.value = t('login.emailMsgEmpty')
            email_status.value = 'error'
            return false
        }
        

        if(captcha_verification.value == ''){
            verifyRef.value.show()
            return
        }
    }


    // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
    const success = (params)=>{ 
        console.log(params,'params')
        captcha_verification.value = params.captchaVerification
        sent()
    }

    //  发送验证码
    const sent = async ()=>{
        sentDisabled.value = true
        let res = await LoginApi.getInstance().getVaildCode(decodeURIComponent(code_email.value), encodeURIComponent(captcha_verification.value))
        if(res.code == 0){
            showForm2.value = false
            let timer= setInterval(()=>{
                if(time.value > 0){
                    time.value = time.value - 1
                    sentCodeTip.value = t('login.sentCodeTip2') + `${time.value}s` + t('login.sentCodeTip3')
                }else{
                    sentDisabled.value = false
                    sentCodeTip.value = t('login.sentCodeTip')
                    time.value = 60
                    clearInterval(timer)
                }
            }, 1000)
        }else{
            captcha_verification.value = ''
            sentDisabled.value = false
            Message.error(res.msg)
        }
    }


    // 确认重置
    const reset = ()=>{
        if(!vaild()) return

        resetPassword()
    }

    const resetPassword = async ()=>{
        let param = {
            email:code_email.value,
            new_password: form.new_password,
            code: form.code
        }
        let res = await LoginApi.getInstance().resetPassword(param)
        if(res.code == 0){
            Message.success(t('login.resetSuccessful'))
            router.replace('/Login')
        }else{
            Message.error(res.msg)
        }
    }

    // const getUserList = async ()=>{
    //     let param = {
    //         email: form.email
    //     }
    //     let res = await LoginApi.getInstance().getUserList(param)
    //     if(res.code == 0){
    //         let item = res.data.find(i=> i.email == form.email)
    //         resetPassword(item.id)
    //     }else{
    //         Message.error(res.msg)
    //     }
    // }

    watch(()=>code_email.value, (val)=>{
        if(val){
            emailMsg.value = ''
            email_status.value = ''
        }
    })

    watch(()=>form.code, (val)=>{
        if(val){
            codeMsg.value = ''
            code_status.value = ''
        }
    })

    watch(()=>form.new_password, (val)=>{
        if(val){
            newPdwMsg.value = ''
            new_password_status.value = ''
        }
    })

    watch(()=>form.password, (val)=>{
        if(val){
            confirmPdwMsg.value = ''
            confirm_password_status.value = ''
        }
    })

    onMounted(()=>{
        sentCodeTip.value = t('login.sentCodeTip')
    })


</script>

<style lang="less" scoped>
.contanier{
    width: 100%;
    height: 100vh;
    background:url('@/assets/image/login_bg.jpg') no-repeat;
    background-size: cover;

    .loginForm{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -280px;
        margin-top: -30vh;
        padding: 45px 20px;
        width: 560px;
        height: auto;
        box-sizing: border-box;
        background: #fff;
        border-radius: 5px; 

        .form_header{
            width: 370px;
            margin: 0 auto 20px;

            .back{
                float: left;
                margin-left: -70px;
                padding: 5px;
                border-radius: 2px;
                cursor: pointer;
                background: #f7f5ff;
            }

            .text{
                margin-left: 65px;
                font-size: 24px;
                font-weight: 700;
                color: #000;
            }

            .subText{
                margin-top: 16px;
                text-align: center;
                font-weight: 400;
                color: #000;
            }
        }

        /deep/ .arco-form-item-layout-vertical > .arco-form-item-label-col{
            margin-bottom: 0;
            font-weight: 600;
        }
       

        /deep/ .arco-form-item-content-wrapper{
            margin-top: 5px;
        }

        /deep/ .arco-input-wrapper .arco-input{
            height: 35px;
        }

        /deep/ button:focus, 
        /deep/ button:focus-visible{
            outline: none;
        }

        .sendBtn,.resetBtn{
            margin: 20px auto 0;
            height: 50px;
            width: 240px;
            font-size: 16px;
            background: #000;
            border-radius: 50px;
        }

        .sendTbnDisabeld{
            background: #999;
        }

        .sentCodeTip{
            margin-top: 10px;
            text-align: center;
        }
        
    }
}
</style>