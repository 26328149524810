<template>
    <div>
        <div class="operayeBox">
            <slot name="operate"></slot>
        </div>

        <a-table 
            ref="tableRefs"
            :columns="table.columns" 
            :data="table.datas"
            v-model:selectedKeys="selectedKeys"
            :hoverable="true"
            :column-resizable="true"
            :filter-icon-align-left="true"
            :summary-text="table.configs.summaryText !== undefined? table.configs.summaryText : ''"
            :scroll="table.configs.scroll !== undefined? table.configs.scroll : ''"
            :stripe="table.configs.stripe !== undefined? table.configs.stripe : true"
            :bordered="table.configs.bordered !== undefined? table.configs.bordered : true"
            :size="table.configs.size !== undefined? table.configs.size : 'large'"
            :show-header="table.configs.showHeader !== undefined? table.configs.showHeader : true"
            :loading="table.configs.loading !== undefined? table.configs.loading : false"
            :pagination="false"
            :virtual-list-props="table.configs.VirtualListProps !== undefined? table.configs.VirtualListProps : null"
            :span-method="table.configs.dataSpanMethod !== undefined? table.configs.dataSpanMethod : null"
            :row-class="record=>rowClass(record,rowIndex)"
            row-key="id"
            :expandable="table.configs.expandable == undefined ? expandable : table.configs.expandable"
            :expanded-keys="expandedKeys"
            :row-selection="table.configs.rowSelection"
            :summary-span-method="table.configs.spanMethod"
            :summary="table.configs.summary"
            @expand="expand"
            @cell-click="cellClick"
            @cell-dblclick=cellDblClick
            @row-click="rowClick"
            @row-dblclick="rowDblclick"
            @select="selectRow"
            @select-all="(checked)=>selectAllRow(table.datas, checked)"
            @sorter-change="sorterChange"
        >   
            <template #searchFilter="{ filterValue, setFilterValue, handleFilterConfirm, handleFilterReset}">
                <slot name="searchFilter" :props="{ filterValue, setFilterValue, handleFilterConfirm, handleFilterReset }"></slot>
            </template>

            <template #slot="{ column, record, rowIndex }">
                <slot name="columns" :props="{ record, column }"></slot>
            </template>

            <template #optional="{ record }">
                <slot name="tableOperate" :props="{ record }"></slot>
            </template>
        </a-table>

        <a-pagination 
            v-if="table.datas.length > 0"
            class="table_pagination"
            v-model:current="table.configs.pagination.current"
            v-model:page-size="table.configs.pagination.pageSize"
            :size="table.configs.pagination.size"
            :total="table.configs.pagination.total" 
            :page-size-options="table.configs.pagination.pageSizeOptions"
            :show-total="table.configs.pagination.showTotal"
            :show-jumper="table.configs.pagination.showJumper" 
            :show-page-size="table.configs.pagination.showPageSize"
            @change="changePage"
            @page-size-change="changePageSize"
        />
    </div>
</template>

<script setup>
    import { ref, reactive } from 'vue';

    let props = defineProps({
        table:{
            type:Object,
            default:()=>{}
        }
    })

    const emits = defineEmits(
        [
            'changePage',
            'changePageSize',
            'expandRow',
            'filterChange',
            'selectRow',
            'selectAllRow',
            'rowDblclick',
            'cellClick',
            'cellDblClick',
            'sorterChange',
            'resetFilters'
        ]
    )

    const alignLeft = ref(false)
    const selectedKeys = ref([])
    const selectedData = ref([])    
    const expandable = reactive({width: 80});
    const expandedKeys = ref([])
    const tableRefs = ref(null)

    const changePage = (current)=>{
        emits('changePage',current)
    }

    const changePageSize = (pageSize)=>{
        emits('changePageSize',pageSize)
    }
    
    const rowClick = (record)=>{
        // console.log(record,'record')
        // expand(record.id, record)
        // emits('rowDblclick')
    }

    const rowDblclick = (record)=>{
        // expand(record.id, record)
        // emits('rowDblclick')
    }

    
    const cellClick = (record,column)=>{
        emits('cellClick',record,column)
    }

    const cellDblClick = (record,column)=>{
        emits('cellDblClick',record,column)
    }

    const sorterChange = (dataIndex,direction)=>{
        // console.log(dataIndex,direction,'dataIndex,direction')
        emits('sorterChange',dataIndex,direction)
    }

    // 展开子表格
    const expand = (rowKey, record)=>{
        // console.log(rowKey, record,'rowKey, record')

        // if(!expandedKeys.value.includes(rowKey)){
        //     expandedKeys.value.push(rowKey)
        // }else{
        //     let index = expandedKeys.value.findIndex(i=> i == rowKey)
        //     expandedKeys.value.splice(index,1)
        // }

        // selectedData.value = []
        // emits('expandRow',rowKey,record)
    }

    // 过滤
    const filterChange = (dataIndex, filteredValues)=>{
        emits('filterChange',dataIndex,filteredValues)
    }

    // 重置过滤条件
    const resetFilter = (dataIndex)=>{
        // console.log(dataIndex,'dataIndex')
        tableRefs.value.resetFilters()
    }

    const rowClass = (record,rowIndex)=>{
        // console.log(record,rowIndex,'record,rowIndex')
    }


    // 选择器--单选
    const selectRow = (rowKeys, rowKey, record )=>{
        // console.log(rowKeys, rowKey, record ,'rowKeys, rowKey, record ')
        selectedKeys.value = rowKeys
        if(rowKeys.includes(rowKey)){
            selectedData.value.push(record)
        }else{
            let itemIndex = selectedData.value.findIndex(i=> i.id == rowKey)
            selectedData.value.splice(itemIndex,1)
        }
        emits('selectRow', selectedData.value)
    }

    // 选择器--全选
    const selectAllRow =(data, checked)=>{
        // console.log(checked,'checked', data)
        let arr = []
        data.forEach(i=>{
            if(!i.disabled){
                arr.push(i)
            }
        })
        selectedData.value = checked? arr : []
        emits('selectAllRow', selectedData.value)
    }

    // 单元格合并
    // const dataSpanMethod = ({record, column})=>{
        // console.log({record, column})
        // if (record.menu === 'Order' && column.dataIndex === 'menu') {
        //     return {
        //         rowspan: 3,
        //     }
        // }
    // }

    defineExpose({
        // expandedKeys,
        selectedKeys,
        selectedData,
        resetFilter,
        // rowClick
    })

</script>

<style lang="less" scoped>
.operayeBox{
    margin-bottom: 7px;
}
/deep/ .arco-table-container{
    padding-bottom:20px;
}
.table_pagination{
    margin-top: 10px;
}
</style>