/*
 * @Author: caizexin caizexin@kjgb.net
 * @Date: 2022-11-22 17:11:27
 */
import { Data } from '@/api/axiosFun.js';

class OrderApi {
    static getInstance() {
        if (!this.instance) this.instance = new OrderApi()
        return this.instance
    }

    // 获取数据
    async getData(url, param, method, dataType) {
        let result = await Data.getInstance().getData(url, param, method, dataType)
        return result
    }

    // 获取订单列表
    async getOrderList (param){
        let url = `/api/product/model/inventory`
        let result = await this.getData(url,param,'GET')
        return result
    }

    // 保存订单
    async saveOrder (param){
        let url = `/api/business/order/head/save`
        let result = await this.getData(url,param,'POST')
        return result
    }

    // 获取经销商信息
    async getDealer (param){
        let url = `/api/system/source/query`
        let result = await this.getData(url,param,'POST')
        return result
    }

    // 获取销售-经销商列表
    async getSaleDealerList (param){
        let url = `/api/system/source/sale/query`
        let result = await this.getData(url,param,'POST')
        return result
    }

}

export {
    OrderApi
}